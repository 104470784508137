import React from 'react';
import {
	DetailsInfo,
	DetailsInfoContent,
	DetailsInfoTexts,
	DetailsInfoTitle,
} from '../styledComponents';
import ten from '../../../assets/icons/ten.png';
import sdt from '../../../assets/icons/sdt.png';
import email from '../../../assets/icons/email.png';
import diachi from '../../../assets/icons/diachi.png';

const DestinationDetailsInfo = ({ destination }) => {
	return (
		<DetailsInfo
			style={{
				marginTop: '2rem',
				marginBottom: '2rem',
			}}
		>
			<DetailsInfoTitle>
				<h5>Thông tin bên nhận hàng</h5>
			</DetailsInfoTitle>
			<DetailsInfoContent>
				<DetailsInfoTexts>
					<table>
						<tbody>
							<tr>
								<td>
									<img src={ten} alt="ten" />
									<span>Tên:</span>
								</td>
								<td>{destination?.ten}</td>
							</tr>
							<tr>
								<td>
									<img src={sdt} alt="sdt" />
									<span>SĐT:</span>
								</td>
								<td>{destination?.sdt}</td>
							</tr>
							<tr>
								<td>
									<img src={email} alt="cmnd" />
									<span>E-mail</span>
								</td>
								<td>{destination?.email}</td>
							</tr>
							<tr>
								<td>
									<img src={diachi} alt="diachi" />
									<span>Địa chỉ:</span>
								</td>
								<td>{`${destination?.xa}, ${destination?.huyen}, ${destination?.tinh}`}</td>
							</tr>
						</tbody>
					</table>
				</DetailsInfoTexts>
			</DetailsInfoContent>
		</DetailsInfo>
	);
};

export default DestinationDetailsInfo;
