import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { getComparator } from '../../../utils';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { headCellsDaily1 } from './headCells';
import DialogMaterial from '../../../components/DialogMaterial';
import apiDaily1 from '../../../axios/apiDaily1';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import TableButton from '../../../components/TableButton';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Popup from '../../../popup/Popup';
import { useContext } from 'react';
import { StateContext } from '../../../Context/StateContext';

const EnhancedTableToolbar = ({
	numSelected,
	rowsSelected,
	onClickChitiet,
	onClickCapnhat,
	onClickXoa,
}) => {
	return numSelected > 0 ? (
		<>
			<Toolbar
				sx={{
					pl: { sm: 7 },
					pr: { xs: 1, sm: 1 },
					...(numSelected > 0 && {
						bgcolor: (theme) =>
							alpha(
								theme.palette.primary.main,
								theme.palette.action.activatedOpacity
							),
					}),
				}}
			>
				{numSelected > 0 ? (
					<Typography
						sx={{ flex: '1 1 100%' }}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						<div className="d-flex align-items-center">
							{rowsSelected.length === 1 ? (
								<>
									<TableButton onClick={onClickChitiet}>
										Chi tiết
									</TableButton>
									<TableButton onClick={onClickCapnhat}>
										Cập nhật
									</TableButton>
									<TableButton onClick={onClickXoa}>
										Xóa
									</TableButton>
								</>
							) : (
								<TableButton onClick={onClickXoa}>
									Xóa
								</TableButton>
							)}
						</div>
					</Typography>
				) : (
					<Typography
						sx={{ flex: '1 1 100%' }}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						Nutrition
					</Typography>
				)}
			</Toolbar>
		</>
	) : null;
};

const TableDaily1 = ({ dsDaily1 = [], setRowsRemoved, readOnly }) => {
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('ten');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);
	const [open, setOpen] = React.useState(false);
	const history = useHistory();

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const onClickChitiet = () =>
		history.push(`/giamsatvung/daily1/chitiet/${selected[0]}`);

	const onClickCapnhat = () =>
		history.push(`/giamsatvung/daily1/chinhsua/${selected[0]}`);

	const onClickXoa = () => handleOpen();

	const handleDeleteRow = async () => {
		const { success } = await apiDaily1.xoaNhieuDaily1({
			arrayOfId: selected,
		});
		if (success) {
			toast.success('Xóa thành công!', { theme: 'colored' });
			setRowsRemoved(true);
		}
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = dsDaily1?.map((item) => item._id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, _id) => {
		const selectedIndex = selected.indexOf(_id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, _id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (_id) => selected.indexOf(_id) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dsDaily1?.length) : 0;
	const context = useContext(StateContext);
	return (
		<>
			<Popup show={context.show} />
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					{!readOnly && (
						<EnhancedTableToolbar
							numSelected={selected.length}
							rowsSelected={selected}
							onClickChitiet={onClickChitiet}
							onClickCapnhat={onClickCapnhat}
							onClickXoa={onClickXoa}
						/>
					)}
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={dsDaily1?.length}
								headCells={headCellsDaily1}
							/>
							<TableBody>
								{dsDaily1
									?.slice()
									.sort(getComparator(order, orderBy))
									.slice(
										rowsPerPage > 0
											? page * rowsPerPage
											: 0,
										rowsPerPage > 0
											? page * rowsPerPage + rowsPerPage
											: dsDaily1?.length
									)
									.map((row, index) => {
										const isItemSelected = isSelected(
											row._id
										);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												onClick={(event) => {
													handleClick(event, row._id);
												}}
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row._id}
												selected={isItemSelected}
											>
												<TableCell padding="checkbox">
													<Checkbox
														color="primary"
														checked={isItemSelected}
														inputProps={{
															'aria-labelledby':
																labelId,
														}}
													/>
												</TableCell>
												<TableCell align="right">
													{readOnly ? (
														row.ten
													) : (
														<Link
															to={`/giamsatvung/daily1/chitiet/${row._id}`}
														>
															{row.ten}
														</Link>
													)}
												</TableCell>
												<TableCell
													align="right"
													onClick={() => {
														context.handleGetQrcode(
															row.user,
															'daily1',
															row.active
														);
													}}
												>
													{row.sdt}
												</TableCell>
												<TableCell
													align="right"
													onClick={() => {
														context.handleGetQrcode(
															row.user,
															'daily1',
															row.active
														);
													}}
												>
													{row.email}
												</TableCell>
												<TableCell
													align="right"
													onClick={() => {
														context.handleGetQrcode(
															row.user,
															'daily1',
															row.active
														);
													}}
												>
													{row.taikhoan}
												</TableCell>
												<TableCell align="right">
													{row.active ? (
														<Badge className="success">
															Đã kích hoạt
														</Badge>
													) : (
														<Badge className="danger">
															Chờ duyệt
														</Badge>
													)}
												</TableCell>
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[
							20,
							30,
							40,
							{ label: 'All', value: -1 },
						]}
						colSpan={3}
						count={dsDaily1?.length}
						rowsPerPage={rowsPerPage}
						page={page}
						SelectProps={{
							inputProps: {
								'aria-label': 'số dòng trên trang',
							},
							native: true,
						}}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
						component="div"
					/>
				</Paper>
			</Box>

			<DialogMaterial
				open={open}
				onClose={handleClose}
				title="Xóa đại lý"
				content="Bạn chắc xóa những đại lý này chứ?"
				text1="Hủy"
				text2="Xóa"
				onClick1={handleClose}
				onClick2={handleDeleteRow}
			/>
		</>
	);
};

const Badge = styled.div`
	display: inline-block;
	text-align: center;
	color: #fff;
	padding: 6px 10px;
	font-size: 13px;
	border-radius: 3px;
	&.success {
		background-color: #28a745;
	}
	&.danger {
		background-color: #dc3545;
	}
`;

export default TableDaily1;
