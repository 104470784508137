import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { headCellsDaily2 } from './headCells';
import DialogMaterial from '../../../components/DialogMaterial';
import styled from 'styled-components';
import apiGSV from '../../../axios/apiGSV';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { StateContext } from '../../../Context/StateContext';
import Popup from '../../../popup/Popup';

import { useTablePagination } from '../../../hooks/useTablePagination';
import { tablePaginationOptions } from './tablePaginationOptions';

const TableDaily2 = ({
	dsDaily2 = [],
	gsvId,
	setSuccess,
	setRefresh,
	readOnly,
}) => {
	const pagination = useTablePagination(dsDaily2, {
		sortBy: 'ten',
	});

	const [modalDuyetOpen, setModalDuyetOpen] = React.useState(false);
	const [rowOfDuyet, setrowOfDuyet] = React.useState('');

	const handleOpenModalDuyet = async (id) => setModalDuyetOpen(true);

	const onClickDuyet = () => handleOpenModalDuyet();

	const handleDuyetDaily1 = async () => {
		const { success } = await apiGSV.activeDaily2(rowOfDuyet, gsvId);
		if (success) {
			setRefresh(true);
			toast.success('Duyệt thành công!', { theme: 'colored' });
			setSuccess(true);
		}
	};

	const handleCloseModalDuyet = () => setModalDuyetOpen(false);

	const context = useContext(StateContext);

	return (
		<>
			<Popup show={context.show} />
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={pagination.handleSelectAllClick}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsDaily2.length}
								headCells={headCellsDaily2}
							/>
							<TableBody>
								{pagination.sortedItems.map((row, index) => {
									const isItemSelected = pagination.isSelected(row._id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) => {
												pagination.handleRowClick(event, row._id);
											}}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row._id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>
											<TableCell align="right">
												{readOnly ? (
													row.ten
												) : (
													<Link to={`/giamsatvung/daily2/chitiet/${row._id}`}>
														{row.ten}
													</Link>
												)}
											</TableCell>
											<TableCell
												align="right"
												onClick={() => {
													context.handleGetQrcode(
														row.user,
														'daily2',
														row.active
													);
												}}
											>
												{row.sdt}
											</TableCell>
											<TableCell
												align="right"
												onClick={() => {
													context.handleGetQrcode(
														row.user,
														'daily2',
														row.active
													);
												}}
											>
												{row.email}
											</TableCell>
											<TableCell
												align="right"
												onClick={() => {
													context.handleGetQrcode(
														row.user,
														'daily2',
														row.active
													);
												}}
											>
												{row.taikhoan}
											</TableCell>
											<TableCell align="right">
												{row.active ? (
													<Badge className="success">Đã kích hoạt</Badge>
												) : !row.giamsatvung ? (
													<Badge className="danger">Chờ duyệt</Badge>
												) : (
													<Badge className="danger">Chờ BPKD</Badge>
												)}
											</TableCell>
											<TableCell align="right">
												{!row.giamsatvung ? (
													readOnly ? (
														<button
															type="button"
															className="btn btn-outline-danger"
														>
															Chờ duyệt...
														</button>
													) : (
														<button
															className="btn btn-info"
															onClick={() => {
																onClickDuyet();
																setrowOfDuyet(row._id);
															}}
														>
															<i className="fas fa-user-edit"></i> Duyệt
														</button>
													)
												) : (
													<button
														type="button"
														className="btn btn-outline-info"
													>
														<i className="fas fa-check"></i> Đã duyệt
													</button>
												)}
											</TableCell>
										</TableRow>
									);
								})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={dsDaily2.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>

			<DialogMaterial
				open={modalDuyetOpen}
				onClose={handleCloseModalDuyet}
				title="Duyệt đại lý"
				content="Xác nhận đại lý đăng ký tài khoản"
				text1="Hủy"
				text2="Đồng ý"
				onClick1={handleCloseModalDuyet}
				onClick2={handleDuyetDaily1}
			/>
		</>
	);
};

const Badge = styled.div`
	display: inline-block;
	text-align: center;
	color: #fff;
	padding: 6px 10px;
	font-size: 13px;
	border-radius: 3px;
	&.success {
		background-color: #28a745;
	}
	&.danger {
		background-color: #dc3545;
	}
`;

export default TableDaily2;
