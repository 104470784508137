import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AdminRoutes from './AdminRoutes';
import BophankdRoutes from './BophankdRoutes';
import GiamsatvienRoutes from './GsVienRoutes';
import NotfoundPage from '../components/NotfoundPage';
import Daily1Routes from './Daily1Routes';
import Daily2Routes from './Daily2Routes';
import GsvRoutes from './GsvRoutes';
import LoginPage from '../components/pages/LoginPage';
import Detailsuser from '../components/Detailsuser/Detailsuser';
import { useState, useEffect } from 'react';
import axiosClient from '../axios/axiosClient';

const RouteProvider = () => {
	const [users, setUsers] = useState([]);
	useEffect(() => {
		async function getAllUsers() {
			try {
				const res = await axiosClient.get('/users/getAll');
				setUsers(res.user);
			} catch (err) {
				console.log(err);
			}
		}
		getAllUsers();
	}, []);

	return (
		<Router>
			<Switch>
				<Route
					exact
					path="/"
					component={LoginPage}
				/>
				<Route
					path="/admin"
					component={AdminRoutes}
				/>
				<Route
					path="/bophankd"
					component={BophankdRoutes}
				/>
				<Route
					path="/giamsatvien"
					component={GiamsatvienRoutes}
				/>
				<Route
					path="/giamsatvung"
					component={GsvRoutes}
				/>
				<Route
					path="/daily1"
					component={Daily1Routes}
				/>
				<Route
					path="/daily2"
					component={Daily2Routes}
				/>
				{users &&
					users.map((user) => {
						let path = `/public/details/user/${user._id.toString()}`;
						return (
							<Route
								key={user._id.toString()}
								path={path}
								render={(props) => (
									<Detailsuser
										{...props}
										id={user._id.toString()}
										user={user.taikhoan}
										role={user.vaitro}
									/>
								)}
							/>
						);
					})}
				<Route
					path="*"
					component={NotfoundPage}
				/>
			</Switch>
		</Router>
	);
};

export default RouteProvider;
