export const headCellsCongcu = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Tổng số lượng',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsCongcuHuloi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsVattu = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Tổng số lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: true,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsVattuHuloi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: true,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsNguyenlieu = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'khoiluong',
		numeric: true,
		disablePadding: false,
		label: 'Tổng khối lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	// {
	//   id: "donvitinh",
	//   numeric: false,
	//   disablePadding: false,
	//   label: "Đơn vị tính",
	// },
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsNguyenlieuHuloi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'khoiluong',
		numeric: true,
		disablePadding: false,
		label: 'Khối lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	// {
	//   id: "donvitinh",
	//   numeric: false,
	//   disablePadding: false,
	//   label: "Đơn vị tính",
	// },
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsKhohang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'cotheban',
		numeric: true,
		disablePadding: false,
		label: 'Có thể bán',
	},
	{
		id: 'tonkho',
		numeric: true,
		disablePadding: false,
		label: 'Tồn kho',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày khởi tạo',
	},
	{
		id: 'giabanle',
		numeric: true,
		disablePadding: false,
		label: 'Giá bản lẻ (VNĐ)',
	},
	{
		id: 'giabanbuon',
		numeric: true,
		disablePadding: false,
		label: 'Giá bán buôn (VNĐ)',
	},
];

export const headCellsDaily1 = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: false,
		label: 'Trạng thái',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: false,
		label: '',
	},
];

export const headCellsGSV = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
];

export const headCellsPhanphat = [
	{
		id: 'hodan',
		numeric: false,
		disablePadding: true,
		label: 'Hộ dân',
	},
	{
		id: 'dailytrunggian',
		numeric: false,
		disablePadding: false,
		label: 'Đại lý trung gian',
	},
	{
		id: 'tongcongcu',
		numeric: true,
		disablePadding: false,
		label: 'Tổng số công cụ',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: false,
		label: 'Trạng thái',
	},
	{
		id: 'hoanthanh',
		numeric: false,
		disablePadding: false,
		label: 'Hoàn thành',
	},
	{
		id: 'chitiet',
		numeric: false,
		disablePadding: false,
		label: 'Chi tiết',
	},
];

export const headCellsHodan = [
	{
		id: 'daidien',
		numeric: false,
		disablePadding: true,
		label: 'Đại diện',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'cmnd',
		numeric: false,
		disablePadding: false,
		label: 'CMND',
	},

	{
		id: 'namsinh',
		numeric: true,
		disablePadding: false,
		label: 'Năm sinh',
	},
	{
		id: 'nghe',
		numeric: false,
		disablePadding: false,
		label: 'Nghề',
	},
	{
		id: 'langnghe',
		numeric: false,
		disablePadding: false,
		label: 'Làng nghề',
	},
];

export const headCellsHodan2 = [
	{
		id: 'daidien',
		numeric: false,
		disablePadding: true,
		label: 'Đại diện',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: true,
		label: 'Số điện thoại',
	},
	// {
	// 	id: 'cmnd',
	// 	numeric: false,
	// 	disablePadding: true,
	// 	label: 'CMND',
	// },
	{
		id: 'namsinh',
		numeric: true,
		disablePadding: true,
		label: 'Năm sinh',
	},
	{
		id: 'loaisp',
		numeric: false,
		disablePadding: true,
		label: 'Loại sản phẩm',
	},
	{
		id: 'langnghe',
		numeric: false,
		disablePadding: true,
		label: 'Làng nghề',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: true,
		label: 'Trạng thái',
	},
];

export const headCellsTiendo = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên tiến độ',
	},
	{
		id: 'noidung',
		numeric: false,
		disablePadding: true,
		label: 'Nội dung',
	},
	{
		id: 'sanpham',
		numeric: false,
		disablePadding: true,
		label: 'Sản phẩm phục vụ',
	},
	{
		id: 'dientich',
		numeric: false,
		disablePadding: true,
		label: 'Diện tích',
	},
	{
		id: 'thoigianbatdau',
		numeric: true,
		disablePadding: false,
		label: 'Thời gian bắt đầu',
	},
	{
		id: 'thoigianthuhoach',
		numeric: false,
		disablePadding: false,
		label: 'Thời gian thu hoạch',
	},
];

export const headCellsChitietBaocao = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên báo cáo',
	},
	{
		id: 'noidung',
		numeric: false,
		disablePadding: true,
		label: 'Nội dung',
	},
	{
		id: 'thoigian',
		numeric: false,
		disablePadding: true,
		label: 'Thời gian',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
];

export const headCellsDaily2 = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên đại lý',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: false,
		label: 'Trạng thái',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: false,
		label: '',
	},
];

export const headCellsDonhangGoc = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: true,
		disablePadding: false,
		label: 'Tên',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'gia',
		numeric: false,
		disablePadding: false,
		label: 'Giá (VNĐ)',
	},
];

export const headCellsDonhang = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'tongsanpham',
		numeric: false,
		disablePadding: false,
		label: 'Tổng sản phẩm',
	},
	{
		id: 'tongcongcu',
		numeric: true,
		disablePadding: false,
		label: 'Tổng công cụ',
	},
	{
		id: 'tongvattu',
		numeric: false,
		disablePadding: false,
		label: 'Tổng vật tư',
	},
	{
		id: 'tongnguyenlieu',
		numeric: false,
		disablePadding: false,
		label: 'Tổng nguyên liệu',
	},
	{
		id: 'tonggia',
		numeric: false,
		disablePadding: false,
		label: 'Tổng giá (VNĐ)',
	},
	{
		id: 'ngaydathang',
		numeric: false,
		disablePadding: false,
		label: 'Ngày đặt hàng',
	},
];

export const headCellsCongcuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
];

export const headCellsSanphamDonhang = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'dongia',
		numeric: false,
		disablePadding: false,
		label: 'Đơn giá (VNĐ)',
	},
	{
		id: 'tonggia',
		numeric: false,
		disablePadding: false,
		label: 'Tổng giá (VNĐ)',
	},
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
];

export const headCellsSanphamDonhangChitiet = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'soluonght',
		numeric: false,
		disablePadding: false,
		label: 'Hộ dân hoàn thành',
	},
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'dongia',
		numeric: false,
		disablePadding: false,
		label: 'Đơn giá (VNĐ)',
	},
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
];

export const headCellsVattuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
];

export const headCellsNguyenlieuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'khoiluong',
		numeric: false,
		disablePadding: false,
		label: 'Khối lượng',
	},
];

export const headCellsSanpham = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'masanpham',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Tổng số lượng',
	},
	{
		id: 'soluonghoanthanh',
		numeric: false,
		disablePadding: false,
		label: 'Hộ dân hoàn thành',
	},
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'qrcode',
		numeric: false,
		disablePadding: false,
		label: 'QR Code',
	},
	{
		id: 'tonggia',
		numeric: false,
		disablePadding: false,
		label: 'Tổng giá (VNĐ)',
	},
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
];

export const headCellsHanggiaodenChitiet = [
	{
		id: 'masp',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'tensp',
		numeric: false,
		disablePadding: true,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'slthuchien',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng thực hiện',
	},
	// {
	// 	id: 'slhoanthanh',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Hộ dân hoàn thành',
	// },
	{
		id: 'sldanhan',
		numeric: false,
		disablePadding: false,
		label: 'Giám sát vùng đã nhận',
	},
	{
		id: 'soluonggiao',
		numeric: false,
		disablePadding: false,
		label: 'Số luợng giao',
	},
];

export const headCellsGiaohangDen = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'gsv',
		numeric: false,
		disablePadding: true,
		label: 'Giám sát vùng',
	},
	{
		id: 'tongsanpham',
		numeric: false,
		disablePadding: false,
		label: 'Tổng sản phẩm',
	},
	{
		id: 'ngaygiao',
		numeric: false,
		disablePadding: true,
		label: 'Ngày giao đến',
	},
	{
		id: 'xacnhan',
		numeric: false,
		disablePadding: true,
		label: 'Ngày xác nhận',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: true,
		label: '',
	},
];
