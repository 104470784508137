import React, { useEffect, useState } from 'react';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import TableVattu from './tables/TableVattu';
import apiVattu from '../../axios/apiVattu';
import {
	AddButton,
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import { headerDropdownLinks } from './arrayOfLinks';

import { useSearch } from '../../hooks/useSearch';

const Vattu = (props) => {
	const { queryText, setQueryText, search } = useSearch(['ten', 'congdung']);

	const [loading, setLoading] = useState(false);
	const [dsVattu, setDsVattu] = useState([]);
	const [rowsRemoved, setRowsRemoved] = useState(false);

	useEffect(() => {
		setRowsRemoved(false);

		const fetchDsVattu = async () => {
			setLoading(true);
			const { vattu } = await apiVattu.dsVattu();
			setDsVattu(vattu);
			setLoading(false);
		};
		fetchDsVattu();
	}, [rowsRemoved]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Vật tư"
					arrOfLinks={headerDropdownLinks}
					vaitro="admin"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách vật tư</Title>
							<AddButton
								className="btn btn-primary"
								onClick={() =>
									props.history.push('/admin/vattu/them')
								}
							>
								<span>Thêm</span>
								<i className="fas fa-plus-circle"></i>
							</AddButton>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tim vật tư theo tên, công dụng"
									value={queryText}
									onChange={(e) =>
										setQueryText(e.target.value)
									}
								/>
							</SearchBox>
						</Filter>
						<TableSection>
							<TableVattu
								dsVattu={search(dsVattu)}
								setRowsRemoved={setRowsRemoved}
							/>
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Vattu;
