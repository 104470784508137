import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const Input = styled('input')({
	display: 'none',
});

const UploadButton = ({ onChange }) => {
	return (
		<label>
			<Input
				accept="image/*"
				type="file"
				multiple={true}
				onChange={onChange}
			/>
			<Button variant="contained" component="span">
				<span>Upload</span>
				<i
					className="fas fa-upload"
					style={{ fontSize: 18, marginLeft: 10 }}
				></i>
			</Button>
		</label>
	);
};

export default UploadButton;
