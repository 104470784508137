import React from 'react';
import TableDaily1 from './tables/TableDaily1';
import BackdropMaterial from '../../components/BackdropMaterial';
import apiBophankd from '../../axios/apiBophankd';
import { useSelector } from 'react-redux';
import {
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
	Container,
} from './styledComponents';
import Header from '../../components/Header';
import { headerDropdownLinks } from './arrayOfLinks';

import { useSearch } from '../../hooks/useSearch';

const Daily1 = (props) => {
	const { queryText, setQueryText, search } = useSearch([
		'ten',
		'sdt',
		'email',
		'taikhoan',
	]);

	const [loading, setLoading] = React.useState(false);
	const [dsDaily1, setDsDaily1] = React.useState([]);
	const { userInfo } = useSelector((state) => state.user);

	React.useEffect(() => {
		let isMounted = true;
		const fetchDsDaily1 = async () => {
			setLoading(true);
			const { bophankd } = await apiBophankd.bophankdReadOnlyUserId(
				userInfo._id
			);
			const {
				daily1: { daily1 },
			} = await apiBophankd.bophankdDsDaily1(bophankd._id);

			if (!isMounted) return;

			setDsDaily1(daily1 && daily1.length ? daily1 : []);
			setLoading(false);
		};
		fetchDsDaily1();

		return () => {
			isMounted = false;
		};
	}, [userInfo._id]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Đại lý cấp 1"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvien"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách đại lý cấp 1</Title>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tim đại lý theo tên, số điện thoại, email và tài khoản"
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
								/>
							</SearchBox>
						</Filter>

						<TableSection className="noCheckbox">
							<TableDaily1 dsDaily1={search(dsDaily1)} />
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Daily1;
