import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from 'react-router-dom';
import img_placeholder from '../../../assets/images/img_placeholder.png';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { formatMoney } from '../../../utils';
import { headCellsSanpham } from './headCells';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import DialogMaterial from '../../../components/DialogMaterial';
import TableButton from '../../../components/TableButton';
import apiSanpham from '../../../axios/apiSanpham';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axiosClient from '../../../axios/axiosClient';

import { useTablePagination } from '../../../hooks/useTablePagination';
import { tablePaginationOptions } from './tablePaginationOptions';
import { formatddMMyyyy } from '../../../utils';

const EnhancedTableToolbar = ({
	numSelected,
	rowsSelected,
	onClickChitiet,
	onClickCapnhat,
	handleRedirectToTaisudung,
	onClickXoa,
}) => {
	return numSelected > 0 ? (
		<>
			<Toolbar
				sx={{
					pl: { sm: 7 },
					pr: { xs: 1, sm: 1 },
					...(numSelected > 0 && {
						bgcolor: (theme) =>
							alpha(
								theme.palette.primary.main,
								theme.palette.action.activatedOpacity
							),
					}),
				}}
			>
				{numSelected > 0 ? (
					<Typography
						sx={{ flex: '1 1 100%' }}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						<div className="d-flex align-items-center">
							{rowsSelected.length === 1 ? (
								<>
									<TableButton onClick={onClickChitiet}>Chi tiết</TableButton>
									<TableButton onClick={onClickCapnhat}>Cập nhật</TableButton>
									<TableButton onClick={onClickXoa}>Xóa</TableButton>
									<TableButton onClick={handleRedirectToTaisudung}>
										Tái sử dụng
									</TableButton>
								</>
							) : (
								<TableButton onClick={onClickXoa}>Xóa</TableButton>
							)}
						</div>
					</Typography>
				) : (
					<Typography
						sx={{ flex: '1 1 100%' }}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						Nutrition
					</Typography>
				)}
			</Toolbar>
		</>
	) : null;
};

const TableSanpham = ({
	dsSanpham = [],
	dsSanphamDangDung = [],
	setRowsRemoved,
}) => {
	const pagination = useTablePagination(dsSanpham, {
		sortDirection: 'asc',
		sortBy: 'ten',
	});

	const [open, setOpen] = React.useState(false);
	const history = useHistory();

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const onClickChitiet = () =>
		history.push(`/admin/sanpham/chitiet/${pagination.selectedItems[0]}`);

	const onClickCapnhat = () =>
		history.push(`/admin/sanpham/chinhsua/${pagination.selectedItems[0]}`);

	const onClickXoa = () => handleOpen();

	const handleDeleteRows = async () => {
		if (
			pagination.selectedItems.some((item) => dsSanphamDangDung.includes(item))
		) {
			toast.error('Không thể xóa sản phẩm đang sử dụng trong đơn hàng!', {
				theme: 'colored',
			});
			return;
		}
		const { success } = await apiSanpham.xoaNhieuSanpham({
			arrOfIds: pagination.selectedItems,
		});
		if (success) {
			toast.success('Xóa thành công!', { theme: 'colored' });
			setRowsRemoved(true);
		}
	};

	const handleRedirectToTaisudung = () => {
		history.push(`/admin/sanpham/taisudung/${pagination.selectedItems[0]}`);
	};

	// Get and Render Qrcode
	const [qrcode, setQrcode] = useState([]);
	useEffect(() => {
		let isMounted = true;
		const getQrcode = async () => {
			try {
				const res = await axiosClient.post('/qrcode/scan', {
					role: JSON.parse(localStorage.getItem('userInfo')).vaitro,
				});
				if (isMounted) {
					setQrcode(res.listId);
				}
			} catch (error) {
				console.log(error);
			}
		};
		getQrcode();
		return () => {
			isMounted = false;
		};
	}, []);
	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<EnhancedTableToolbar
						numSelected={pagination.selectedItems.length}
						rowsSelected={pagination.selectedItems}
						onClickChitiet={onClickChitiet}
						onClickCapnhat={onClickCapnhat}
						onClickXoa={onClickXoa}
						handleRedirectToTaisudung={handleRedirectToTaisudung}
					/>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={pagination.handleSelectAllClick}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsSanpham.length}
								headCells={headCellsSanpham}
							/>
							<TableBody>
								{pagination.sortedItems.map((row, index) => {
									const isItemSelected = pagination.isSelected(row?._id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) =>
												pagination.handleRowClick(event, row?._id)
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row?._id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>
											<TableCell align="right">
												<Link to={`/admin/sanpham/chitiet/${row?._id}`}>
													{row.ma}
												</Link>
											</TableCell>
											<TableCell
												component="th"
												id={labelId}
												scope="row"
												padding="none"
											>
												<img
													src={
														row.hinhanh
															? `/uploads/${row.hinhanh}`
															: img_placeholder
													}
													alt="anhcongcu"
													style={{
														width: '30px',
													}}
													className={!row.hinhanh ? 'noImage' : undefined}
												/>
											</TableCell>
											<TableCell align="right">{row.ten}</TableCell>
											<TableCell align="right">
												{row?.loaisanpham?.ten}
											</TableCell>
											<TableCell align="right">
												{formatMoney(row.gia)}
											</TableCell>
											<TableCell align="right">
												{qrcode
													.filter((qr) => qr.id === row?._id)
													.map((qr) => {
														return (
															<img
																key={qr.id}
																alt="qrcode"
																style={{
																	width: '60px',
																	height: '60px',
																}}
																src={qr.qrcode}
															/>
														);
													})}
											</TableCell>
											<TableCell align="right">
												{formatddMMyyyy(row.ngaytao)}
											</TableCell>
										</TableRow>
									);
								})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>

					<TablePagination
						{...tablePaginationOptions}
						count={dsSanpham?.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>

			<DialogMaterial
				open={open}
				onClose={handleClose}
				title="Xóa sản phẩm"
				content="Bạn chắc xóa sản phẩm này chứ?"
				text1="Hủy"
				text2="Xóa"
				onClick1={handleClose}
				onClick2={handleDeleteRows}
			/>
		</>
	);
};

export default TableSanpham;
