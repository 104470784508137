import axios from 'axios';

const axiosClient = axios.create({
	// baseURL: 'http://27.71.25.147:8080/api',
	baseURL: 'http://27.71.25.147:8080/api',
	// baseURL: 'http://172.30.33.49:8080/api',
	// baseURL: 'http://146.190.80.34:8080/api',
	headers: {
		'Content-Type': 'application/json',
	},
});

axiosClient.defaults.timeout = 60000;

axiosClient.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		return config;
	},
	function (error) {
		// Do something with request error
		// console.log(error);
		return Promise.reject(error);
	}
);

axiosClient.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response.data;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		// console.log(error);
		return Promise.reject(error);
	}
);
export default axiosClient;
