import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Link, useHistory } from 'react-router-dom';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { headCellsLoaiSanpham } from './headCells';
import DialogMaterial from '../../../components/DialogMaterial';
import { toast } from 'react-toastify';
import apiLoaiSanpham from '../../../axios/apiLoaiSanpham';

import ThreeActionsToolbar from '../../../components/table/ThreeActionsToolbar';
import { useTablePagination } from '../../../hooks/useTablePagination';
import { tablePaginationOptions } from './tablePaginationOptions';
import { formatddMMyyyy } from '../../../utils';

const TableLoaiSanpham = ({ dsSanpham = [], onTableUpdated }) => {
	const pagination = useTablePagination(dsSanpham, {
		sortBy: 'ten',
	});

	const [shouldShowDialog, showDialog] = React.useState(false);

	const handleDeleteRows = async () => {
		const { success } = await apiLoaiSanpham.xoaNhieuLoaiSanpham({
			arrOfIds: pagination.selectedItems,
		});
		if (success) {
			toast.success('Xóa thành công!', { theme: 'colored' });
			onTableUpdated();
		}
	};

	const history = useHistory();
	const onClickDetail = () =>
		history.push(
			`/admin/loaisanpham/chitiet/${pagination.selectedItems[0]}`
		);

	const onClickUpdate = () =>
		history.push(
			`/admin/loaisanpham/chinhsua/${pagination.selectedItems[0]}`
		);

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<ThreeActionsToolbar
						enable={pagination.selectedItems.length > 0}
						rowsSelected={pagination.selectedItems}
						onClickDetail={onClickDetail}
						onClickUpdate={onClickUpdate}
						onClickDelete={() => showDialog(true)}
					/>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={
									pagination.handleSelectAllClick
								}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsSanpham.length}
								headCells={headCellsLoaiSanpham}
							/>
							<TableBody>
								{pagination.sortedItems.map((row, index) => {
									const isItemSelected =
										pagination.isSelected(row._id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) =>
												pagination.handleRowClick(
													event,
													row._id
												)
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row._id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby':
															labelId,
													}}
												/>
											</TableCell>
											<TableCell align="right">
												<Link
													to={`/admin/loaisanpham/chitiet/${row._id}`}
												>
													{row.ma}
												</Link>
											</TableCell>
											<TableCell align="right">
												{row.ten}
											</TableCell>
											<TableCell align="right">
												{row.mota}
											</TableCell>
											<TableCell align="right">
												{formatddMMyyyy(row.ngaytao)}
											</TableCell>
										</TableRow>
									);
								})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={dsSanpham.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>

			<DialogMaterial
				open={shouldShowDialog}
				onClose={() => showDialog(false)}
				title="Xóa sản phẩm làng nghề"
				content="Bạn chắc xóa sản phẩm làng nghề này chứ?"
				text1="Hủy"
				text2="Xóa"
				onClick1={() => showDialog(false)}
				onClick2={handleDeleteRows}
			/>
		</>
	);
};

export default TableLoaiSanpham;
