import React from 'react';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import {
	Container,
	Content,
	Form,
	FormContent,
	FormGroup,
	FormTitle,
	Input,
	Label,
	TableSection,
} from './styledComponents';

import { useChitietGiaohang } from '../shared/giaohang/useChitietGiaohang';
import TableHanggiaodiChitiet from '../shared/giaohang/TableHanggiaodiChitiet';

import DefectDetailsInfo from '../shared/giaohang/DefectDetailsInfo';
import DestinationDetailsInfo from '../shared/giaohang/DestinationDetailsInfo';

const HanggiaodiChitiet = (props) => {
	const { id: giaohangId } = props.match.params;
	const { isLoading, singleDelivery } = useChitietGiaohang(giaohangId);

	if (isLoading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Quay lại danh sách sản phẩm"
					titleBack
					onClick={() => props.history.push('/daily1/hanggiaodi')}
				/>
				<Content>
					<DestinationDetailsInfo
						destination={singleDelivery?.donhang.from.giamsatvung}
					/>

					<Form className="pb-2">
						<FormContent>
							<FormTitle>
								<span>Chi tiết giao hàng</span>
							</FormTitle>

							<FormGroup>
								<Label>
									<span>Mã đơn hàng:</span>
								</Label>
								<Input
									type="text"
									value={singleDelivery?.donhang.ma}
									disabled
								/>
							</FormGroup>
						</FormContent>

						<div className="px-3 pt-5 pb-5 mb-5">
							<TableSection className="noCheckbox">
								<TableHanggiaodiChitiet
									dsGiaohang={singleDelivery?.dssanpham}
								/>
							</TableSection>
							<DefectDetailsInfo
								productList={singleDelivery?.dssanpham}
							/>
						</div>
					</Form>
				</Content>
			</Container>
		</>
	);
};

export default HanggiaodiChitiet;
