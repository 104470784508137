import { useState } from 'react';

export function useSearch(searchAttributes) {
	const [queryText, setQueryText] = useState('');
	const search = (searchItems) => {
		return (
			searchItems &&
			searchItems.filter((item) =>
				searchAttributes.some(
					(col) =>
						item[col]
							.toString()
							.toLowerCase()
							.indexOf(queryText.toLowerCase()) > -1
				)
			)
		);
	};

	return {
		queryText,
		setQueryText,
		search,
	};
}
