import React, { useRef, useCallback } from 'react';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import {
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import TableDonhang from './tables/TableDonhang';
import apiBophankd from '../../axios/apiBophankd';
import { useSelector } from 'react-redux';
import { headerDropdownLinks } from './arrayOfLinks';

import { useSearch } from '../../hooks/useSearch';

const Donhang = (props) => {
	const [loading, setLoading] = React.useState(false);
	const [dsDonhang, setDsDonhang] = React.useState([]);
	const { userInfo } = useSelector((state) => state.user);
	const mountedRef = useRef(true);

	const { queryText, setQueryText, search } = useSearch(['ma']);

	const fetchDsSanpham = useCallback(async () => {
		setLoading(true);
		const { bophankd } = await apiBophankd.bophankdReadOnlyUserId(userInfo._id);
		const { donhang } = await apiBophankd.dsDonhang(bophankd._id);

		if (!mountedRef.current) return;

		setDsDonhang(donhang);
		setLoading(false);
	}, [userInfo._id]);

	React.useEffect(() => {
		fetchDsSanpham();
		return () => {
			mountedRef.current = false;
		};
	}, [fetchDsSanpham]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Đơn hàng"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvien"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách đơn hàng</Title>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tìm đơn hàng theo mã"
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
								/>
							</SearchBox>
						</Filter>

						<TableSection className="noCheckbox">
							<TableDonhang dsDonhang={search(dsDonhang)} />
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Donhang;
