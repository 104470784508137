import React from 'react';
import BackdropMaterial from '../../components/BackdropMaterial';
import apiBophankd from '../../axios/apiBophankd';
import { useSelector } from 'react-redux';
import {
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import Header from '../../components/Header';
import TableGiamsatvung from './tables/TableGiamsatvung';
import { headerDropdownLinks } from './arrayOfLinks';

import { useSearch } from '../../hooks/useSearch';

const Giamsatvung = (props) => {
	const { queryText, setQueryText, search } = useSearch([
		'ten',
		'sdt',
		'email',
		'taikhoan',
	]);

	const [loading, setLoading] = React.useState(false);
	const [dsGiamsatvung, setDsGiamsatvung] = React.useState([]);
	const { userInfo } = useSelector((state) => state.user);

	React.useEffect(() => {
		let isMounted = true;
		const fetchDsDaily1 = async () => {
			setLoading(true);
			const { bophankd } = await apiBophankd.bophankdReadOnlyUserId(
				userInfo._id
			);
			const { giamsatvung } = await apiBophankd.bophankdDsGSV(bophankd._id);

			if (!isMounted) return;

			setDsGiamsatvung(
				giamsatvung && giamsatvung.length
					? giamsatvung.map((item) => ({
							...item,
							taikhoan: item.user ? item.user.taikhoan : '',
					  }))
					: []
			);
			setLoading(false);
		};
		fetchDsDaily1();

		return () => {
			isMounted = false;
		};
	}, [userInfo._id]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Giám sát vùng"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvien"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách giám sát vùng</Title>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tim giám sát vùng theo tên, công dụng"
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
								/>
							</SearchBox>
						</Filter>

						<TableSection className="noCheckbox">
							<TableGiamsatvung dsGiamsatvung={search(dsGiamsatvung)} />
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Giamsatvung;
