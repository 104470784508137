import React, { useEffect, useState, useMemo } from 'react';
import {
	Container,
	Content,
	ErrMsg,
	Form,
	FormContent,
	FormGroup,
	FormTitle,
	Input,
	Label,
} from './styledComponents';
import Header from '../../components/Header';
import { apiTinhThanh } from '../../apiTinhThanh';
import apiHodan from '../../axios/apiHodan';
import apiLangnghe from '../../axios/apiLangnghe';
import BackdropMaterial from '../../components/BackdropMaterial';
import { useSelector } from 'react-redux';
import apiDaily1 from '../../axios/apiDaily1';
import { toast } from 'react-toastify';
import DropdownMaterial2 from '../../components/DropdownMaterial2';
import MenuItem from '@mui/material/MenuItem';
import tenIcon from '../../assets/icons/ten.png';
import sdtIcon from '../../assets/icons/sdt.png';
import diachiIcon from '../../assets/icons/diachi.png';
import taikhoanIcon from '../../assets/icons/taikhoan.png';
import cmndIcon from '../../assets/icons/cmnd.png';
import loaiIcon from '../../assets/icons/loai.png';
import dl2Icon from '../../assets/icons/daily2_2.png';
import namsinhIcon from '../../assets/icons/namsinh.png';
import langngheIcon from '../../assets/icons/langnghe_2.png';
import SaveButton from '../../components/SaveButton';

const HodanThem = (props) => {
	const [hodan, setHodan] = useState({
		daidien: '',
		taikhoan: '',
		sdt: '',
		cmnd: '',
		namsinh: '',
		xa: '',
		tinh: '',
		huyen: '',
		langnghe: '',
		loaisanpham: '',
		daily1: '',
		daily2: '',
	});
	const [dsLangnghe, setDsLangnghe] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [dsDaily2, setDsDaily2] = useState([]);
	const [dsLoaiSP, setdsLoaiSP] = useState([]);
	const { userInfo } = useSelector((state) => state.user);
	const [dsTaikhoan, setDsTaikhoan] = useState([]);
	const [taikhoanErr, setTaikhoanErr] = useState('');
	const [success, setSuccess] = useState(false);

	const handleChangeTaikhoan = (e) => {
		var format = /[!@#$%^&*()+=[\]{};':"\\|,.<>/?]+/;
		const val = e.target.value.toLowerCase();
		setHodan({ ...hodan, taikhoan: val });
		// check white space
		if (val.indexOf(' ') >= 0) {
			setTaikhoanErr('Tài khoản không có khoảng trắng');
		} else if (dsTaikhoan.includes(val)) {
			// check maSP exist
			setTaikhoanErr('Tài khoản đã tồn tại');
		} else if (format.test(val)) {
			// check contains special chars
			setTaikhoanErr('Tài khoản không được chứa kí tự đặc biệt');
		} else {
			setTaikhoanErr('');
		}
	};

	const dsXa = useMemo(
		() =>
			apiTinhThanh
				.find((item) => item.name === hodan.tinh)
				?.districts.find((item) => item.name === hodan.huyen)
				?.wards.map((item) => item.name),
		[hodan.tinh, hodan.huyen]
	);

	const validateFields = () => {
		if (taikhoanErr) {
			return false;
		}
		if (!hodan.taikhoan) {
			setTaikhoanErr('Thông tin không được để trống');
			return false;
		}
		if (hodan.taikhoan.length < 6) {
			setTaikhoanErr('Tài khoản có ít nhất 6 kí tự');
			return false;
		}
		if (
			!hodan.daidien ||
			!hodan.sdt ||
			!hodan.cmnd ||
			!hodan.namsinh ||
			!hodan.langnghe ||
			!hodan.xa ||
			!hodan.loaisanpham ||
			!hodan.daily2
		) {
			setErrMsg('Thông tin không được để trống');
			return false;
		}
		return true;
	};

	const handleSubmit = async () => {
		if (validateFields()) {
			let { savedHodan, success } = await apiHodan.themHodan(hodan);
			if (success) {
				let result = await apiDaily1.activeHodan(
					savedHodan._id,
					hodan.daily1
				);
				if (result.success) {
					toast.success('Thêm hộ dân thành công!', {
						theme: 'colored',
					});
					resetFields();
					setSuccess(true);
				}
			}
		}
	};

	const resetFields = () => {
		setHodan({
			daidien: '',
			taikhoan: '',
			sdt: '',
			cmnd: '',
			namsinh: '',
			xa: '',
			tinh: '',
			huyen: '',
			langnghe: '',
			loaisanpham: '',
			daily1: '',
			daily2: '',
		});
		setErrMsg('');
		setdsLoaiSP([]);
	};

	const fetchFormData = async () => {
		setLoading(true);
		const { langnghe } = await apiLangnghe.dsLangnghe();
		const { daily1 } = await apiDaily1.singleDaily1BasedUser(userInfo._id);
		const { daily2 } = await apiDaily1.dsDaily2(daily1._id);
		const { hodan } = await apiHodan.dsHodan();
		setDsTaikhoan(hodan ? hodan.map((hodan) => hodan.taikhoan) : []);
		setDsLangnghe(langnghe);
		setDsDaily2(daily2);
		setLoading(false);
	};

	useEffect(() => {
		setSuccess(false);
		fetchFormData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [success]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<Container>
			<Header
				title="Quay lại danh sách hộ dân"
				titleBack
				onClick={() => props.history.push('/daily1/hodan')}
				headerRight={<SaveButton onClick={handleSubmit} />}
			/>
			<Content>
				<Form>
					<FormContent>
						<FormTitle>
							<span>Thêm hộ dân</span>
						</FormTitle>

						<FormGroup>
							<Label>
								<img src={dl2Icon} alt="daily2" />
								<span>Đại lý 2:</span>
							</Label>
							{dsDaily2 && dsDaily2.length ? (
								<DropdownMaterial2
									label="Chọn đại lý 2"
									value={hodan.daily2}
									onChange={(e) => {
										let daily2Info = dsDaily2.find(
											(item) =>
												item._id === e.target.value
										);
										setHodan((state) => ({
											...state,
											daily2: e.target.value,
											daily1: daily2Info.daily1,
										}));
									}}
								>
									{dsDaily2.map((item) => (
										<MenuItem
											value={item._id || ''}
											key={item._id || ''}
										>
											{item.ten || ''}
										</MenuItem>
									))}
								</DropdownMaterial2>
							) : (
								<DropdownMaterial2 label="Chọn đại lý 2" />
							)}
							{!hodan.daily2 && <ErrMsg>{errMsg}</ErrMsg>}
						</FormGroup>

						<FormGroup>
							<Label>
								<img src={tenIcon} alt="ten" />
								<span>Tên hộ dân:</span>
							</Label>
							<Input
								placeholder="Nhập tên"
								type="text"
								name="daidien"
								value={hodan.daidien}
								onChange={(e) => {
									setHodan((state) => ({
										...state,
										daidien: e.target.value,
									}));
								}}
							/>
							{!hodan.daidien && <ErrMsg>{errMsg}</ErrMsg>}
						</FormGroup>

						<FormGroup>
							<Label>
								<img src={sdtIcon} alt="sdt" />
								<span>Số điện thoại:</span>
							</Label>
							<Input
								placeholder="Nhập sđt"
								type="text"
								name="sdt"
								value={hodan.sdt}
								onChange={(e) => {
									setHodan((state) => ({
										...state,
										sdt: e.target.value,
									}));
								}}
							/>
							{!hodan.sdt && <ErrMsg>{errMsg}</ErrMsg>}
						</FormGroup>

						<FormGroup>
							<Label>
								<img src={cmndIcon} alt="cmnd" />
								<span>Chứng minh nhân dân:</span>
							</Label>
							<Input
								placeholder="Nhập cmnd"
								type="text"
								name="cmnd"
								value={hodan.cmnd}
								onChange={(e) => {
									setHodan((state) => ({
										...state,
										cmnd: e.target.value,
									}));
								}}
							/>
							{!hodan.cmnd && <ErrMsg>{errMsg}</ErrMsg>}
						</FormGroup>

						<FormGroup>
							<Label>
								<img src={namsinhIcon} alt="namsinh" />
								<span>Năm sinh:</span>
							</Label>
							<Input
								placeholder="Nhập năm sinh"
								type="text"
								name="namsinh"
								value={hodan.namsinh}
								onChange={(e) => {
									setHodan((state) => ({
										...state,
										namsinh: e.target.value,
									}));
								}}
							/>
							{!hodan.namsinh && <ErrMsg>{errMsg}</ErrMsg>}
						</FormGroup>

						<div className="row">
							<div className="col-lg-6">
								<FormGroup>
									<Label>
										<img
											src={langngheIcon}
											alt="langnghe"
										/>
										<span>Làng nghề:</span>
									</Label>
									{dsLangnghe && dsLangnghe.length ? (
										<DropdownMaterial2
											label="Chọn Làng nghề"
											value={hodan.langnghe}
											onChange={(e) => {
												const {
													tinh,
													huyen,
													loaisanpham,
												} = dsLangnghe.find(
													(item) =>
														item._id ===
														e.target.value
												);

												setdsLoaiSP(loaisanpham);
												setHodan((state) => ({
													...state,
													xa: '',
													huyen,
													tinh,
													langnghe: e.target.value,
													loaisanpham: '',
												}));
											}}
										>
											{dsLangnghe &&
												dsLangnghe.length &&
												dsLangnghe.map((item) => (
													<MenuItem
														value={item._id || ''}
														key={item._id || ''}
													>
														{item.ten || ''}
													</MenuItem>
												))}
										</DropdownMaterial2>
									) : (
										<DropdownMaterial2 label="Chọn làng nghề" />
									)}
									{!hodan.langnghe && (
										<ErrMsg>{errMsg}</ErrMsg>
									)}
								</FormGroup>
							</div>

							<div className="col-lg-6">
								<FormGroup>
									<Label>
										<img src={diachiIcon} alt="diachi" />
										<span>Nơi cư trú:</span>
									</Label>
									{dsXa && dsXa.length ? (
										<DropdownMaterial2
											label="Chọn xã"
											value={hodan.xa}
											onChange={(e) =>
												setHodan((state) => ({
													...state,
													xa: e.target.value,
												}))
											}
										>
											{dsXa.map((item) => (
												<MenuItem
													value={item || ''}
													key={item || ''}
												>
													{item || ''}
												</MenuItem>
											))}
										</DropdownMaterial2>
									) : (
										<DropdownMaterial2 label="Chọn xã" />
									)}
									{!hodan.xa && <ErrMsg>{errMsg}</ErrMsg>}
								</FormGroup>
							</div>
						</div>

						<FormGroup>
							<Label>
								<img src={loaiIcon} alt="loai" />
								<span>Loại sản phẩm:</span>
							</Label>
							{dsLoaiSP && dsLoaiSP.length ? (
								<DropdownMaterial2
									label="Chọn loại sản phẩm"
									value={hodan.loaisanpham}
									onChange={(e) =>
										setHodan((state) => ({
											...state,
											loaisanpham: e.target.value,
										}))
									}
								>
									{dsLoaiSP.map((item) => (
										<MenuItem
											value={item._id || ''}
											key={item._id || ''}
										>
											{item.ten || ''}
										</MenuItem>
									))}
								</DropdownMaterial2>
							) : (
								<DropdownMaterial2 label="Chọn loại sản phẩm" />
							)}
							{!hodan.loaisanpham && <ErrMsg>{errMsg}</ErrMsg>}
						</FormGroup>

						<FormGroup>
							<Label>
								<img src={taikhoanIcon} alt="taikhoan" />
								<span>Tên tài khoản:</span>
							</Label>
							<Input
								placeholder="Nhập tài khoản"
								type="text"
								name="taikhoan"
								value={hodan.taikhoan}
								onChange={handleChangeTaikhoan}
							/>
							{<ErrMsg>{taikhoanErr}</ErrMsg>}
						</FormGroup>

						<SaveButton onClick={handleSubmit} />
					</FormContent>
				</Form>
			</Content>
		</Container>
	);
};

export default HodanThem;
