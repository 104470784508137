import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import img_placeholder from '../../../assets/images/img_placeholder.png';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { headCellsVattuDonhang } from './headCells';

import { useTablePagination } from '../../../hooks/useTablePagination';
import { tablePaginationOptions } from './tablePaginationOptions';

const TableVattuDonhang = ({ dsVattu = [] }) => {
	const pagination = useTablePagination(
		dsVattu.map((item) => {
			return {
				...item,
				ten: item.vattu.ten,
				congdung: item.vattu.congdung,
			};
		}),
		{
			sortDirection: 'asc',
			sortBy: 'ten',
		}
	);

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={
									pagination.handleSelectAllClick
								}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsVattu?.length}
								headCells={headCellsVattuDonhang}
							/>
							<TableBody>
								{pagination.sortedItems.map((row, index) => {
									const isItemSelected =
										pagination.isSelected(row._id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) =>
												pagination.handleRowClick(
													event,
													row._id
												)
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row._id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby':
															labelId,
													}}
												/>
											</TableCell>
											<TableCell>
												<img
													src={
														row?.vattu.hinhanh
															? `/uploads/${row?.vattu.hinhanh}`
															: img_placeholder
													}
													alt="anhvattu"
													style={{
														width: '30px',
													}}
													className={
														!row?.vattu.hinhanh
															? 'noImage'
															: undefined
													}
												/>
											</TableCell>
											<TableCell align="right">
												{row?.vattu.ten}
											</TableCell>
											<TableCell align="right">
												{row?.vattu.congdung}
											</TableCell>
											<TableCell align="right">
												{row?.soluong}
											</TableCell>
										</TableRow>
									);
								})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={dsVattu?.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>
		</>
	);
};

export default TableVattuDonhang;
