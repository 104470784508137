import { useEffect, useState, useRef, useCallback } from 'react';
import apiGiaohang from '../../../axios/apiGiaohang';

export function useChitietGiaohang(giaohangId) {
	const [isLoading, setLoading] = useState(false);
	const [singleDelivery, setSignleDelivery] = useState(null);
	const isMounted = useRef(true);

	function setConfirmedProductList(productList) {
		setSignleDelivery((prev) => ({
			...prev,
			dssanpham: productList,
		}));
	}

	const fetchChitietGiaohang = useCallback(async () => {
		setLoading(true);
		let { giaohang } = await apiGiaohang.singleGiaohang(giaohangId);
		const dssanpham = giaohang.dssanpham.map((item) => ({
			...item.sanpham,
			...item,
			_id: item.sanpham._id,
			sanpham: item.sanpham._id,
			dagiao: item.dagiao,
			danhan: item.danhan,
			soluongloi: item.soluongloi,
			chitietloi: [...(item.chitietloi ?? [])],
		}));
		// console.log(dssanpham);

		if (!isMounted.current) return null;

		setSignleDelivery({
			...giaohang,
			dssanpham,
		});

		setLoading(false);
	}, [giaohangId]);

	function refresh() {
		fetchChitietGiaohang();
	}

	useEffect(() => {
		fetchChitietGiaohang();
		return () => {
			isMounted.current = false;
		};
	}, [fetchChitietGiaohang]);

	return {
		isLoading,
		singleDelivery,
		refresh,
		setConfirmedProductList,
	};
}
