import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import img_placeholder from '../../../assets/images/img_placeholder.png';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { formatddMMyyyy } from '../../../utils';
import { headCellsSanpham } from './headCells';
import { Link } from 'react-router-dom';
import axiosClient from '../../../axios/axiosClient';
import { useState, useEffect } from 'react';

import { tablePaginationOptions } from './tablePaginationOptions';
import { useTablePagination } from '../../../hooks/useTablePagination';

const TableSanpham = ({ dsSanpham = [], readOnly }) => {
	const pagination = useTablePagination(dsSanpham, {
		sortBy: 'ten',
	});

	//style Qrcode
	const style = {
		width: '60px',
		height: '60px',
	};

	// Get and Render Qrcode
	const [qrcode, setQrcode] = useState([]);

	useEffect(() => {
		let isMounted = true;
		const getQrcode = async () => {
			try {
				const res = await axiosClient.post('/qrcode/scan', {
					role: JSON.parse(localStorage.getItem('userInfo')).vaitro,
				});

				if (!isMounted) return;

				setQrcode(res.listId);
			} catch (error) {
				console.log(error);
			}
		};
		getQrcode();
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={pagination.handleSelectAllClick}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsSanpham.length}
								headCells={headCellsSanpham}
							/>
							<TableBody>
								{pagination.sortedItems.map((row, index) => {
									const isItemSelected = pagination.isSelected(row._id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) =>
												pagination.handleRowClick(event, row._id)
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row._id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>
											<TableCell align="right">
												{readOnly ? (
													row?.donhang.ma
												) : (
													<Link
														to={`/daily1/donhang/chitiet/${row?.donhang._id}`}
													>
														{row?.donhang.ma}
													</Link>
												)}
											</TableCell>
											<TableCell align="right">
												{readOnly ? (
													row?.ma
												) : (
													<Link
														to={`/daily1/sanpham/chitiet/${row?.sanpham._id}`}
													>
														{row?.ma}
													</Link>
												)}
											</TableCell>
											<TableCell align="right">{row?.ten}</TableCell>
											<TableCell
												component="th"
												id={labelId}
												scope="row"
												padding="none"
											>
												<img
													src={
														row?.hinhanh
															? `/uploads/${row?.hinhanh}`
															: img_placeholder
													}
													alt="anhcongcu"
													style={{
														width: '30px',
													}}
													className={!row?.hinhanh ? 'noImage' : undefined}
												/>
											</TableCell>
											<TableCell align="right">{row?.soluong}</TableCell>
											<TableCell align="right">
												{row?.soluonghoanthanh}
											</TableCell>
											<TableCell align="right">{row?.danhan}</TableCell>
											<TableCell align="right">{row?.dagiao}</TableCell>
											<TableCell align="right">
												{row?.danhan - row?.dagiao}
											</TableCell>
											<TableCell align="right">
												{qrcode
													.filter((qr) => qr.id === row?.sanpham._id)
													.map((qr) => {
														return (
															<img
																key={qr.id}
																alt="qrcode"
																style={style}
																src={qr.qrcode}
															/>
														);
													})}
											</TableCell>
											{/* <TableCell align="right">
												{formatMoney(row?.soluong * row?.gia)}
											</TableCell> */}
											<TableCell align="right">
												{formatddMMyyyy(row?.thoihan)}
											</TableCell>
										</TableRow>
									);
								})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={dsSanpham.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>
		</>
	);
};

export default TableSanpham;
