import { Typography } from '@mui/material';
import TableToolbar from './TableToolbar';
import TableButton from '../TableButton';

const ThreeActionsToolbar = ({
	enable,
	rowsSelected,
	onClickDetail,
	onClickUpdate,
	onClickDelete,
}) => {
	return (
		<TableToolbar enable={enable}>
			<Typography
				sx={{ flex: '1 1 100%' }}
				color="inherit"
				variant="subtitle1"
				component="div"
			>
				<div className="d-flex align-items-center">
					{rowsSelected.length === 1 ? (
						<>
							<TableButton onClick={onClickDetail}>
								Chi tiết
							</TableButton>
							<TableButton onClick={onClickUpdate}>
								Cập nhật
							</TableButton>
							<TableButton onClick={onClickDelete}>
								Xóa
							</TableButton>
						</>
					) : (
						<TableButton onClick={onClickDelete}>Xóa</TableButton>
					)}
				</div>
			</Typography>
		</TableToolbar>
	);
};

export default ThreeActionsToolbar;
