export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_FULL_INFO_REQUEST = 'USER_FULL_INFO_REQUEST';
export const USER_FULL_INFO_SUCCESS = 'USER_FULL_INFO_SUCCESS';
export const USER_FULL_INFO_FAIL = 'USER_FULL_INFO_FAIL';

export const USER_SWITCH_PHAN_QUYEN = 'USER_SWITCH_PHAN_QUYEN';
