import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import BackdropMaterial from '../../../components/BackdropMaterial';
import Header from '../../../components/Header';
import {
	Container,
	Content,
	DetailsInfo,
	DetailsInfoContent,
	DetailsInfoTexts,
	DetailsInfoTitle,
	Form,
	FormContent,
	FormGroup,
	FormTitle,
	Input,
	Label,
	TableSection,
} from '../styledComponents';
import TableHanggiaodenChitiet from './TableHanggiaodenChitiet';
import nameAsset from '../../../assets/icons/ten.png';
import telephoneAsset from '../../../assets/icons/sdt.png';
import ciCardAsset from '../../../assets/icons/cmnd.png';
import emailAsset from '../../../assets/icons/email.png';
import addressAsset from '../../../assets/icons/diachi.png';
import DialogMaterial from '../../../components/DialogMaterial';
import MultiUploadButton from '../../../components/MultiUploadButton';

import { useChitietGiaohang } from './useChitietGiaohang';
import { buildFormData } from '../utils/formData';
import ImageListDialog from '../../../components/ImageListDialog';

const ConfirmButton = ({ confirmed, onClick }) => {
	return !confirmed ? (
		<button className="btn btn-primary px-3" onClick={onClick}>
			Xác nhận
		</button>
	) : (
		<button className="btn btn-outline-success px-3">Đã xác nhận</button>
	);
};

const AddDefectButton = ({ onClick }) => {
	return (
		<button className="btn btn-link px-0" onClick={onClick}>
			<i className="fas fa-plus"></i>
			<span> Thêm báo lỗi</span>
		</button>
	);
};

const RemoveDefectButton = ({ onClick }) => {
	return (
		<button className="btn btn-danger px-4 py-2 ml-3" onClick={onClick}>
			<i className="fas fa-times"></i>
		</button>
	);
};

const ConfirmedProductCountInput = ({
	product,
	onChange,
	disabled = false,
}) => {
	return (
		<FormGroup
			style={{
				marginTop: 30,
				marginBottom: 10,
			}}
		>
			<div className="d-flex justify-content-start align-items-center">
				<Label className="d-block">Số lượng hàng nhận</Label>
				<div className="ml-2">
					<Input
						placeholder="Số lượng hàng nhận"
						type="number"
						name="xacnhan"
						min={0}
						max={disabled ? product.danhan : product.dagiao}
						value={product.danhan}
						onChange={onChange}
						disabled={disabled}
					/>
				</div>
			</div>
		</FormGroup>
	);
};

const DefectNoteInput = ({ value, onChange, disabled = false }) => {
	return (
		<Input
			type="text"
			name="ghichu"
			value={value}
			onChange={onChange}
			placeholder="Ghi chú lỗi"
			disabled={disabled}
		/>
	);
};

const DefectCountInput = ({ max, value, onChange, disabled = false }) => {
	return (
		<Input
			type="number"
			name="soluong"
			min={0}
			max={max}
			value={value}
			onChange={onChange}
			placeholder="Số lượng lỗi"
			disabled={disabled}
		/>
	);
};

const SenderDetailsInfo = ({ bengiao }) => {
	return (
		<DetailsInfo
			style={{
				marginTop: '2rem',
				marginBottom: '2rem',
			}}
		>
			<DetailsInfoTitle>
				<h5>Thông tin bên giao hàng</h5>
			</DetailsInfoTitle>
			<DetailsInfoContent>
				<DetailsInfoTexts>
					<table>
						<tbody>
							<tr>
								<td>
									<img src={nameAsset} alt="ten" />
									<span>Tên:</span>
								</td>
								<td>
									{bengiao?.ten
										? bengiao?.ten
										: bengiao?.daidien}
								</td>
							</tr>
							<tr>
								<td>
									<img src={telephoneAsset} alt="sdt" />
									<span>SĐT:</span>
								</td>
								<td>{bengiao?.sdt}</td>
							</tr>
							{bengiao?.email ? (
								<tr>
									<td>
										<img src={emailAsset} alt="email" />
										<span>E-mail</span>
									</td>
									<td>{bengiao?.email}</td>
								</tr>
							) : (
								<tr>
									<td>
										<img src={ciCardAsset} alt="cmnd" />
										<span>CMND</span>
									</td>
									<td>{bengiao?.cmnd}</td>
								</tr>
							)}
							<tr>
								<td>
									<img src={addressAsset} alt="diachi" />
									<span>Địa chỉ:</span>
								</td>
								<td>{`${bengiao?.diachi}`}</td>
							</tr>
						</tbody>
					</table>
				</DetailsInfoTexts>
			</DetailsInfoContent>
		</DetailsInfo>
	);
};

const HanggiaodenChitiet = (props) => {
	const { deliveryId, apiConfirmDelivery, getSenderInfo, vaitro } = props;

	const [shouldShowImageDialog, showImageDialog] = useState(false);
	const [imageData, setImageData] = useState({
		description: '',
		images: [],
	});

	const [shouldShowDialog, showDialog] = useState(false);
	const history = useHistory();

	const { isLoading, singleDelivery, refresh, setConfirmedProductList } =
		useChitietGiaohang(deliveryId);

	const confirmedProductList = singleDelivery?.dssanpham ?? [];

	const bengiao = getSenderInfo(singleDelivery);
	if (bengiao) {
		bengiao.diachi = `${bengiao.xa}, ${bengiao.huyen}, ${bengiao.tinh}`;
	}

	const handleDeliveryConfirmed = async () => {
		let formData = new FormData();
		buildFormData(formData, {
			dssanpham: confirmedProductList,
		});
		// console.log('formData', [...formData]);
		const { success } = await apiConfirmDelivery(deliveryId, formData);
		if (success) {
			showDialog(false);
			toast.success('Xác nhận thành công!', { theme: 'colored' });
			refresh();
		}
	};

	function newDefectDetails(index) {
		let chitietloi = {
			ghichu: '',
			soluong: 0,
			soanhloi: 0,
			anhloi: [],
		};
		const newConfirmedProductList = confirmedProductList.map((item, i) => {
			if (i === index) {
				return {
					...item,
					chitietloi: [...item.chitietloi, chitietloi],
				};
			}
			return item;
		});
		setConfirmedProductList(newConfirmedProductList);
	}

	function removeDefectDetails(productIndex, defectIndex) {
		const newConfirmedProductList = confirmedProductList.map((item, i) => {
			if (i === productIndex) {
				let currentCount = item.chitietloi[defectIndex].soluong;
				return {
					...item,
					soluongloi: item.soluongloi - currentCount,
					chitietloi: item.chitietloi.filter(
						(item, index) => index !== defectIndex
					),
				};
			}
			return item;
		});
		setConfirmedProductList(newConfirmedProductList);
	}

	function handleDefectCountChanged(value, productIndex, defectIndex) {
		const newConfirmedProductList = confirmedProductList.map((item, i) => {
			if (i === productIndex) {
				let currentCount = item.chitietloi[defectIndex].soluong;
				return {
					...item,
					soluongloi: item.soluongloi - currentCount + value,
					chitietloi: item.chitietloi.map((item, index) => {
						if (index === defectIndex) {
							return {
								...item,
								soluong: value,
							};
						}
						return item;
					}),
				};
			}
			return item;
		});
		setConfirmedProductList(newConfirmedProductList);
	}

	function handleDefectNoteChanged(value, productIndex, defectIndex) {
		const newConfirmedProductList = confirmedProductList.map((item, i) => {
			if (i === productIndex) {
				return {
					...item,
					chitietloi: item.chitietloi.map((item, index) => {
						if (index === defectIndex) {
							return {
								...item,
								ghichu: value,
							};
						}
						return item;
					}),
				};
			}
			return item;
		});
		setConfirmedProductList(newConfirmedProductList);
	}

	function handleConfirmedProductCountChanged(value, productIndex) {
		const newConfirmedProductList = confirmedProductList.map((item, i) => {
			if (i === productIndex) {
				return {
					...item,
					danhan: value,
				};
			}
			return item;
		});
		setConfirmedProductList(newConfirmedProductList);
	}

	async function handleDefectUploadChanged(e, productIndex, defectIndex) {
		let files = e.target.files;
		if (files.length > 5) {
			files = [...files].slice(0, 5);
		}
		const newConfirmedProductList = confirmedProductList.map((item, i) => {
			if (i === productIndex) {
				return {
					...item,
					chitietloi: item.chitietloi.map((defect, j) => {
						if (j === defectIndex) {
							return {
								...defect,
								soanhloi: files.length,
								anhloi: [...files],
							};
						}
						return defect;
					}),
				};
			}
			return item;
		});
		setConfirmedProductList(newConfirmedProductList);
	}

	if (isLoading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Quay lại danh sách sản phẩm"
					titleBack
					onClick={() => history.push(`/${vaitro}/hanggiaoden`)}
					headerRight={
						<ConfirmButton
							confirmed={singleDelivery?.xacnhan}
							onClick={() => showDialog(true)}
						/>
					}
				/>
				<Content>
					<SenderDetailsInfo bengiao={bengiao} />

					<Form className="pb-2">
						<FormContent>
							<FormTitle>
								<span>Chi tiết nhận hàng</span>
							</FormTitle>

							<FormGroup>
								<Label>
									<span>Mã đơn hàng:</span>
								</Label>
								<Input
									type="text"
									value={singleDelivery?.donhang.ma ?? ''}
									disabled
								/>
							</FormGroup>
						</FormContent>

						<div className="px-3 pt-5">
							<TableSection className="noCheckbox">
								<TableHanggiaodenChitiet
									dsGiaohang={singleDelivery?.dssanpham}
								/>
							</TableSection>
						</div>

						<FormContent>
							{confirmedProductList.length > 0 &&
								confirmedProductList.map(
									(productItem, productIndex) => {
										return (
											<div key={productIndex}>
												<h6>
													Chi tiết nhận hàng:{' '}
													{productItem?.ma} -{' '}
													{productItem?.ten}
												</h6>
												<ConfirmedProductCountInput
													product={productItem}
													onChange={(e) => {
														const { value } =
															e.target;
														handleConfirmedProductCountChanged(
															parseInt(value),
															productIndex
														);
													}}
													disabled={
														singleDelivery?.xacnhan
													}
												/>

												{productItem.chitietloi.length >
												0 ? (
													<FormGroup
														style={{
															marginBottom: 10,
														}}
													>
														<Label className="d-block">
															Chi tiết lỗi
														</Label>
													</FormGroup>
												) : null}

												{productItem.chitietloi.map(
													(
														defectItem,
														defectIndex
													) => {
														return (
															<div
																className="row mb-2"
																key={
																	defectIndex
																}
															>
																<div className="col-lg-6">
																	<DefectNoteInput
																		value={
																			defectItem.ghichu
																		}
																		onChange={(
																			e
																		) => {
																			const {
																				value,
																			} =
																				e.target;
																			handleDefectNoteChanged(
																				value,
																				productIndex,
																				defectIndex
																			);
																		}}
																		disabled={
																			singleDelivery?.xacnhan
																		}
																	/>
																</div>
																<div className="col-lg-6">
																	<div className="d-flex align-items-center">
																		<div>
																			<DefectCountInput
																				max={
																					productItem.dagiao
																				}
																				value={
																					defectItem.soluong
																				}
																				onChange={(
																					e
																				) => {
																					const {
																						value,
																					} =
																						e.target;
																					handleDefectCountChanged(
																						parseInt(
																							value
																						),
																						productIndex,
																						defectIndex
																					);
																				}}
																				disabled={
																					singleDelivery?.xacnhan
																				}
																			/>
																		</div>
																		{!singleDelivery?.xacnhan ? (
																			<>
																				<div className="ml-3">
																					<MultiUploadButton
																						onChange={(
																							e
																						) => {
																							handleDefectUploadChanged(
																								e,
																								productIndex,
																								defectIndex
																							);
																						}}
																					/>
																				</div>
																				<div className="ml-1">
																					<span>
																						(
																						{
																							confirmedProductList[
																								productIndex
																							]
																								.chitietloi[
																								defectIndex
																							]
																								.soanhloi
																						}

																						)
																					</span>
																				</div>{' '}
																			</>
																		) : null}
																		{singleDelivery?.xacnhan ? (
																			<div className="ml-3">
																				<span
																					className="text-primary text-decoration-underline"
																					onClick={() => {
																						let images =
																							confirmedProductList[
																								productIndex
																							]
																								.chitietloi[
																								defectIndex
																							]
																								.anhloi;
																						let description =
																							confirmedProductList[
																								productIndex
																							]
																								.chitietloi[
																								defectIndex
																							]
																								.ghichu;

																						if (
																							images.length ===
																							0
																						)
																							return;

																						setImageData(
																							{
																								description,
																								images,
																							}
																						);
																						showImageDialog(
																							true
																						);
																					}}
																				>
																					Ảnh
																					lỗi
																					(
																					{
																						confirmedProductList[
																							productIndex
																						]
																							.chitietloi[
																							defectIndex
																						]
																							.anhloi
																							.length
																					}

																					)
																				</span>
																			</div>
																		) : null}
																		{!singleDelivery?.xacnhan ? (
																			<RemoveDefectButton
																				onClick={() =>
																					removeDefectDetails(
																						productIndex,
																						defectIndex
																					)
																				}
																			/>
																		) : null}
																	</div>
																</div>
															</div>
														);
													}
												)}
												{!singleDelivery?.xacnhan ? (
													<AddDefectButton
														onClick={() =>
															newDefectDetails(
																productIndex
															)
														}
													/>
												) : null}
												<div className="mb-3"></div>
											</div>
										);
									}
								)}

							<ConfirmButton
								confirmed={singleDelivery?.xacnhan}
								onClick={() => showDialog(true)}
							/>
							<div className="mb-5"></div>
						</FormContent>
					</Form>
				</Content>
			</Container>

			<DialogMaterial
				open={shouldShowDialog}
				onClose={() => showDialog(false)}
				title="Xác nhận"
				content="Xác nhận đơn hàng giao đến"
				text1="Hủy"
				text2="Đồng ý"
				onClick1={() => showDialog(false)}
				onClick2={handleDeliveryConfirmed}
			/>

			<ImageListDialog
				shouldOpen={shouldShowImageDialog}
				onClose={() => showImageDialog(false)}
				imageData={imageData}
			/>
		</>
	);
};

export default HanggiaodenChitiet;
