import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Link, useHistory } from 'react-router-dom';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { headCellsGiaohangDen } from './headCells';
import styled from 'styled-components';

import { tablePaginationOptions } from './tablePaginationOptions';
import { useTablePagination } from '../../../hooks/useTablePagination';
import { formatddMMyyyy } from '../../../utils';

const TableHanggiaoden = ({ dsGiaohang = [] }) => {
	const pagination = useTablePagination(dsGiaohang, {
		sortDirection: 'desc',
		sortBy: 'ngaygiao',
	});

	const history = useHistory();

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={
									pagination.handleSelectAllClick
								}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsGiaohang.length}
								headCells={headCellsGiaohangDen}
							/>
							<TableBody>
								{pagination.sortedItems.map((row, index) => {
									const isItemSelected =
										pagination.isSelected(row._id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) =>
												pagination.handleRowClick(
													event,
													row._id
												)
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row._id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby':
															labelId,
													}}
												/>
											</TableCell>
											<TableCell align="right">
												<Link
													to={`/daily1/hanggiaoden/chitiet/${row._id}`}
												>
													{row.donhang.ma}
												</Link>
											</TableCell>

											<TableCell align="right">{`${row.daily2.ten}`}</TableCell>
											<TableCell align="right">
												{row.tongsanpham}
											</TableCell>
											<TableCell align="right">
												{formatddMMyyyy(row.ngaygiao)}
											</TableCell>
											<TableCell align="right">
												{!row.xacnhan ? (
													<Badge className="danger">
														Đang chờ...
													</Badge>
												) : (
													<Badge className="success">
														{formatddMMyyyy(
															row.ngaynhan
														)}
													</Badge>
												)}
											</TableCell>

											<TableCell align="right">
												{!row.xacnhan ? (
													<button
														className="btn btn-info"
														onClick={() => {
															history.push(
																`/daily1/hanggiaoden/chitiet/${row._id}`
															);
														}}
													>
														<i className="fas fa-user-edit"></i>{' '}
														Xác nhận
													</button>
												) : (
													<button
														type="button"
														className="btn btn-outline-info"
													>
														<i className="fas fa-check"></i>{' '}
														Đã xác nhận
													</button>
												)}
											</TableCell>
										</TableRow>
									);
								})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={dsGiaohang.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>
		</>
	);
};

const Badge = styled.div`
	display: inline-block;
	text-align: center;
	color: #fff;
	padding: 6px 10px;
	font-size: 15px;
	border-radius: 4px;
	&.success {
		background-color: #28a745;
	}
	&.danger {
		background-color: #dc3545;
	}
`;

export default TableHanggiaoden;
