import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { headCellsHodan2 } from './headCells';
import styled from 'styled-components';
import Popup from '../../../popup/Popup';
import { useContext } from 'react';
import { StateContext } from '../../../Context/StateContext';

import { useTablePagination } from '../../../hooks/useTablePagination';
import { tablePaginationOptions } from './tablePaginationOptions';

const TableHodan = ({ dsHodan = [] }) => {
	const pagination = useTablePagination(dsHodan, {
		sortBy: 'daidien',
	});

	const context = useContext(StateContext);

	return (
		<>
			<Popup show={context.show} />
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={pagination.handleSelectAllClick}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsHodan?.length}
								headCells={headCellsHodan2}
							/>
							<TableBody>
								{pagination.sortedItems.map((row, index) => {
									const isItemSelected = pagination.isSelected(row._id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) =>
												pagination.handleRowClick(event, row._id)
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row._id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>
											<TableCell align="right">{row?.daidien}</TableCell>
											<TableCell
												align="right"
												onClick={() => {
													context.handleGetQrcode(
														row.user,
														'hodan',
														row.active
													);
												}}
											>
												{row?.sdt}
											</TableCell>
											<TableCell
												align="right"
												onClick={() => {
													context.handleGetQrcode(
														row.user,
														'hodan',
														row.active
													);
												}}
											>
												{row?.namsinh}
											</TableCell>
											<TableCell
												align="right"
												onClick={() => {
													context.handleGetQrcode(
														row.user,
														'hodan',
														row.active
													);
												}}
											>
												{row?.loaisanpham.ten}
											</TableCell>
											<TableCell
												align="right"
												onClick={() => {
													context.handleGetQrcode(
														row.user,
														'hodan',
														row.active
													);
												}}
											>
												{row?.langnghe}
											</TableCell>
											<TableCell align="right">
												{row?.active ? (
													<Badge className="success">Đã kích hoạt</Badge>
												) : (
													<Badge className="danger">Chờ duyệt</Badge>
												)}
											</TableCell>
										</TableRow>
									);
								})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={dsHodan.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>
		</>
	);
};

const Badge = styled.div`
	display: inline-block;
	text-align: center;
	color: #fff;
	padding: 6px 10px;
	font-size: 13px;
	border-radius: 4px;
	&.success {
		background-color: #28a745;
	}
	&.danger {
		background-color: #dc3545;
	}
`;

export default TableHodan;
