import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../assets/images/logo.png';
import { Route, NavLink } from 'react-router-dom';
import { logout } from '../../redux/actions/userActions';
import TongQuan from './Tongquan';
import Sanpham from './Sanpham';
import SanphamChitiet from './SanphamChitiet';
import Congcu from './Congcu';
import CongcuChitiet from './CongcuChitiet';
import Daily1 from './Daily1';
import Vattu from './Vattu';
import Nguyenlieu from './Nguyenlieu';
import VattuChitiet from './VattuChitiet';
import NguyenlieuChitiet from './NguyenlieuChitiet';
import LogoutButton from '../../components/LogoutButton';
import styled from 'styled-components';
import Daily1Chitiet from './Daily1Chitiet';
import splnIcon from '../../assets/icons/spln.png';
import Giamsatvung from './Giamsatvung';
import Daily2 from './Daily2';
import Daily2Chitiet from './Daily2Chitiet';
import Donhang from './Donhang';
import DonhangChitiet from './DonhangChitiet';
import Tiendo from './Tiendo';
import dl1Icon from '../../assets/icons/daily1.png';
import dl2Icon from '../../assets/icons/daily2.png';
import gsvIcon from '../../assets/icons/gsv.png';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import apiBophankd from '../../axios/apiBophankd';
import BackdropMaterial from '../../components/BackdropMaterial';
import GiamsatvungChitiet from './GiamsatvungChitiet';
import hodanIcon from '../../assets/icons/hodan.png';
import Hodan from './Hodan';
import HodanChitiet from './HodanChitiet';
import Hanggiaoden from './Hanggiaoden';
import HanggiaodenChitiet from './HanggiaodenChitiet';
import ThongtinCanhan from '../../components/pages/ThongtinCanhan';

const Dashboard = (props) => {
	const [loading, setLoading] = useState(false);
	const [dsBadge, setDsBadge] = useState(null);
	const dispatch = useDispatch();
	const { userInfo } = useSelector((state) => state.user);

	const handleLogout = () => {
		dispatch(logout());
		props.history.push('/');
	};

	useEffect(() => {
		let mounted = true;

		const fetchDsBadge = async () => {
			setLoading(true);
			const { bophankd } = await apiBophankd.bophankdReadOnlyUserId(
				userInfo._id
			);
			const data = await apiBophankd.dsShowBadge(bophankd._id);

			if (!mounted) return;

			setDsBadge(data);
			setLoading(false);
		};
		fetchDsBadge();
		return () => {
			mounted = false;
		};
	}, [userInfo._id]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<Container>
			<LeftMenu>
				<Logo>
					<img
						src={logo}
						alt="logo"
					/>
					<span>Làng Nghề</span>
				</Logo>

				<Menu>
					<MenuItem>
						<NavLink
							to="/giamsatvien"
							activeClassName={
								props.match.path === '/giamsatvien' ? 'active' : ''
							}
						>
							<i className="fas fa-home"></i>
							<span className="ml-3">Tổng quan</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/sanpham"
							activeClassName="active"
						>
							<Image
								src={splnIcon}
								alt="splangnghe"
							/>
							<span className="ml-3">Sản phẩm</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/vattu"
							activeClassName="active"
						>
							<i className="fab fa-accusoft"></i>
							<span className="ml-3">Vật tư</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/nguyenlieu"
							activeClassName="active"
						>
							<i className="fab fa-bandcamp"></i>
							<span className="ml-3">Nguyên liệu</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/congcu"
							activeClassName="active"
						>
							<i className="fas fa-tools"></i>
							<span className="ml-3">Công cụ</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/giamsatvung"
							activeClassName="active"
						>
							<Image
								src={gsvIcon}
								alt="gsvIcon"
							/>
							<span className="ml-3">Giám sát vùng</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/daily1"
							activeClassName="active"
						>
							{dsBadge?.daily1Badge > 0 ? (
								<Badge
									badgeContent={dsBadge?.daily1Badge}
									color="secondary"
								>
									<Image
										src={dl1Icon}
										alt="splangnghe"
									/>
								</Badge>
							) : (
								<Image
									src={dl1Icon}
									alt="splangnghe"
								/>
							)}

							<span className="ml-3">Đại lý cấp 1</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/daily2"
							activeClassName="active"
						>
							{dsBadge?.daily2Badge > 0 ? (
								<Badge
									badgeContent={dsBadge?.daily2Badge}
									color="secondary"
								>
									<Image
										src={dl2Icon}
										alt="splangnghe"
									/>
								</Badge>
							) : (
								<Image
									src={dl2Icon}
									alt="splangnghe"
								/>
							)}

							<span className="ml-3">Đại lý cấp 2</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/hodan"
							activeClassName="active"
						>
							<Image
								src={hodanIcon}
								alt="hodan"
							/>
							<span className="ml-3">Hộ dân</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/donhang"
							activeClassName="active"
						>
							<i className="far fa-newspaper"></i>
							<span className="ml-3">Đơn hàng</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<NavLink
							to="/giamsatvien/hanggiaoden"
							activeClassName="active"
						>
							<i className="far fa-newspaper"></i>
							<span className="ml-3">Hàng giao đến</span>
						</NavLink>
					</MenuItem>

					<MenuItem>
						<LogoutButton onClick={handleLogout}>Đăng xuất</LogoutButton>
					</MenuItem>
				</Menu>
			</LeftMenu>

			<RightContent>
				<Route
					exact
					path="/giamsatvien"
					component={TongQuan}
				/>
				<Route
					exact
					path="/giamsatvien/sanpham"
					component={Sanpham}
				/>
				<Route
					path="/giamsatvien/sanpham/chitiet/:id"
					component={SanphamChitiet}
				/>
				<Route
					exact
					path="/giamsatvien/congcu"
					component={Congcu}
				/>
				<Route
					path="/giamsatvien/congcu/chitiet/:id"
					component={CongcuChitiet}
				/>
				<Route
					exact
					path="/giamsatvien/daily1"
					render={(props) => <Daily1 {...props} />}
				/>
				<Route
					exact
					path="/giamsatvien/vattu"
					component={Vattu}
				/>
				<Route
					path="/giamsatvien/vattu/chitiet/:id"
					component={VattuChitiet}
				/>
				<Route
					exact
					path="/giamsatvien/nguyenlieu"
					component={Nguyenlieu}
				/>
				<Route
					path="/giamsatvien/nguyenlieu/chitiet/:id"
					component={NguyenlieuChitiet}
				/>
				<Route
					path="/giamsatvien/daily1/chitiet/:id"
					component={Daily1Chitiet}
				/>
				<Route
					exact
					path="/giamsatvien/giamsatvung"
					component={Giamsatvung}
				/>
				<Route
					path="/giamsatvien/giamsatvung/chitiet/:id"
					component={GiamsatvungChitiet}
				/>
				<Route
					exact
					path="/giamsatvien/daily2"
					render={(props) => <Daily2 {...props} />}
				/>
				<Route
					path="/giamsatvien/daily2/chitiet/:id"
					component={Daily2Chitiet}
				/>

				<Route
					exact
					path="/giamsatvien/donhang"
					component={Donhang}
				/>
				<Route
					exact
					path="/giamsatvien/donhang/chitiet/:id"
					component={DonhangChitiet}
				/>
				<Route
					path="/giamsatvien/donhang/chitiet/:id/tiendo"
					component={Tiendo}
				/>
				<Route
					exact
					path="/giamsatvien/hodan"
					component={Hodan}
				/>
				<Route
					path="/giamsatvien/hodan/chitiet/:id"
					component={HodanChitiet}
				/>

				<Route
					exact
					path="/giamsatvien/hanggiaoden"
					component={Hanggiaoden}
				/>
				<Route
					path="/giamsatvien/hanggiaoden/chitiet/:id"
					component={HanggiaodenChitiet}
				/>

				<Route
					exact
					path="/giamsatvien/thongtincanhan"
					render={(props) => (
						<ThongtinCanhan
							{...props}
							type="giamsatvien"
						/>
					)}
				/>
			</RightContent>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
`;
const LeftMenu = styled.div`
	width: 230px;
	height: 100vh;
	left: 0;
	top: 0;
	position: fixed;
	background-color: #202d3f;
	overflow-x: hidden;
`;
const Logo = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.7);

	img {
		width: 30px;
	}

	span {
		font-size: 20px;
		margin-left: 8px;
		color: #fff;
		font-weight: bold;
	}
`;
const Menu = styled.ul`
	list-style: none;
	width: 100%;
	padding: 0;
	margin: 0;
`;
const MenuItem = styled.li`
	display: block;

	a {
		display: block;
		text-decoration: none;
		padding: 12px;
		display: flex;
		align-items: center;
		font-family: 'Poppins', sans-serif;
		i {
			color: #cad6e2;
			font-size: 22px;
		}
		span {
			color: #cad6e2;
			/* margin-left: 14px; */
		}
		&:hover {
			background: #304664;
			i,
			span {
				color: #fff;
			}
		}
		&.active {
			background: #2e96e0;
			i,
			span {
				color: #fff;
			}
		}
	}
`;
const RightContent = styled.div`
	margin-left: 230px;
	flex: 1;
`;
const Image = styled.img`
	width: 22px;
`;

export default Dashboard;
