import { utils, writeFileXLSX } from 'xlsx';
import { formatddMMyyyy } from '../../../utils';

function getOrderSummarySheet(
	orderModel,
	subOrderProductsMap,
	isManager = false
) {
	let heading = [
		[
			'Mã đơn hàng',
			'Mã sản phẩm',
			'Tên sản phẩm',
			'Số lượng',
			'Giá',
			'Thời hạn',
			'Đã phân phát',
			'Báo cáo hoàn thành',
			'Đã nhận về',
			'Đã giao đi',
		],
	];

	let data = orderModel.dssanpham.map((item) => {
		return {
			madh: orderModel.ma,
			masp: item.sanpham.ma,
			tensp: item.sanpham.ten,
			soluong: item.soluong,
			gia: isManager ? (item.gia ? item.gia : item.sanpham.gia) : 0,
			thoihan: formatddMMyyyy(item.thoihan),
			daphanphat: subOrderProductsMap.get(item.sanpham.ma) ?? 0,
			baocaohoanthanh: item.soluonghoanthanh,
			danhan: item.danhan,
			dagiao: item.dagiao,
		};
	});
	const ws = utils.json_to_sheet([]);
	utils.sheet_add_aoa(ws, heading);
	utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
	return ws;
}

function getOrderDetailSheet(subOrders, isManager = false) {
	let heading = [
		[
			'Mã đơn hàng',
			'Mã sản phẩm',
			'Tên sản phẩm',
			'Giao đến',
			'Điện thoại',
			'Địa chỉ',
			'Số lượng',
			'Giá',
			'Thời hạn',
			'Báo cáo hoàn thành',
			'Đã nhận về',
			'Đã giao đi',
			'Trạng thái',
		],
	];

	let productsMap = new Map();
	let data = [];
	subOrders.forEach((sub) => {
		let destination = Object.values(sub.to)[0];
		let address = `${destination.xa}, ${destination.huyen}, ${destination.tinh}`;
		sub.dssanpham.forEach((item) => {
			if (!productsMap.has(item.sanpham.ma)) {
				productsMap.set(item.sanpham.ma, 0);
			}
			productsMap.set(
				item.sanpham.ma,
				productsMap.get(item.sanpham.ma) + (sub.bihuy ? 0 : item.soluong)
			);
			data.push({
				madh: sub.ma,
				masp: item.sanpham.ma,
				tensp: item.sanpham.ten,
				giaoden: destination.ten ? destination.ten : destination.daidien,
				dienthoai: destination.sdt,
				diachi: address,
				soluong: item.soluong,
				gia: isManager ? item.gia : 0,
				thoihan: formatddMMyyyy(item.thoihan),
				baocaohoanthanh: item.soluonghoanthanh,
				danhan: item.danhan,
				dagiao: item.dagiao,
				trangthai: sub.bihuy
					? 'Bị hủy'
					: sub.xacnhan
					? 'Đã xác nhận'
					: 'Chưa xác nhận',
			});
		});
	});
	const ws = utils.json_to_sheet([]);
	utils.sheet_add_aoa(ws, heading);
	utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
	return {
		ws,
		productsMap,
	};
}

export const exportOrder = (orderModel, fileName, isManager = false) => {
	const wb = utils.book_new();

	const { ws: detailWs, productsMap } = getOrderDetailSheet(
		orderModel.subdonhang,
		isManager
	);
	const summaryWs = getOrderSummarySheet(orderModel, productsMap, isManager);

	utils.book_append_sheet(wb, summaryWs, 'Thong tin don hang');
	utils.book_append_sheet(wb, detailWs, 'Thong tin phan phat');
	writeFileXLSX(wb, fileName);
};
