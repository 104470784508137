import React, { useState, useEffect } from 'react';
import BackdropMaterial from '../../components/BackdropMaterial';
import img_placeholder from '../../assets/images/img_placeholder.png';
import DialogMaterial from '../../components/DialogMaterial';
import apiVattu from '../../axios/apiVattu';
import Header from '../../components/Header';
import { toast } from 'react-toastify';
import _ten from '../../assets/icons/ten.png';
import _mota from '../../assets/icons/mota.png';
import anh from '../../assets/icons/anh.png';
import tt from '../../assets/icons/thuoctinh.png';
import cd from '../../assets/icons/congdung.png';
import _gia from '../../assets/icons/gia.png';
import {
	Container,
	Content,
	Form,
	FormContent,
	FormGroup,
	FormTitle,
	ImageToDisplay,
	Input,
	Label,
	TextArea,
} from './styledComponents';

const VattuChitiet = (props) => {
	const [open, setOpen] = React.useState(false);
	const { id: vattuId } = props.match.params;
	const [loading, setLoading] = useState(false);
	const [vattu, setVattu] = useState({});

	const handleClickOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleDelete = async () => {
		const { success } = await apiVattu.xoa1Vattu(vattuId);
		if (success) {
			toast.success('Xóa vật tư thành công!', { theme: 'colored' });
			props.history.push('/admin/vattu');
		}
	};

	useEffect(() => {
		const fetchVattuObj = async () => {
			setLoading(true);
			const { vattu } = await apiVattu.singleVattu(vattuId);
			setVattu(vattu);
			setLoading(false);
		};
		fetchVattuObj();
	}, [vattuId]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Quay lại trang danh sách vật tư"
					titleBack
					onClick={() => props.history.push('/admin/vattu')}
					headerRight={
						<>
							<button
								className="btn btn-danger px-3"
								onClick={handleClickOpen}
							>
								<span>Xóa</span>
								<i className="fas fa-trash-alt"></i>
							</button>
							<button
								className="btn btn-primary px-2 ml-3"
								onClick={() =>
									props.history.push(
										`/admin/vattu/chinhsua/${vattuId}`
									)
								}
							>
								<span>Chỉnh sửa</span>
								<i className="fas fa-edit"></i>
							</button>
						</>
					}
				/>
				<Content>
					<Form>
						<FormContent>
							<FormTitle>
								<span>Chi tiết vật tư</span>
							</FormTitle>

							<FormGroup>
								<Label>
									<img src={_ten} alt="ten" />
									<span>Tên vật tư:</span>
								</Label>
								<Input
									type="text"
									value={vattu?.ten}
									disabled
								/>
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={_mota} alt="mota" />
									<span>Mô tả vật tư:</span>
								</Label>
								<TextArea
									value={vattu?.mota}
									rows="4"
									disabled
								/>
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={anh} alt="anh" />
									<span>Hình ảnh:</span>
								</Label>
								<ImageToDisplay>
									<img
										src={
											vattu?.hinhanh
												? `/uploads/${vattu?.hinhanh}`
												: img_placeholder
										}
										alt="vattuImg"
										className={
											!vattu?.hinhanh
												? 'noImage'
												: undefined
										}
									/>
								</ImageToDisplay>
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={cd} alt="cd" />
									<span>Công dụng:</span>
								</Label>
								<Input
									type="text"
									value={vattu?.congdung}
									disabled
								/>
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={_gia} alt="_gia" />
									<span>Giá trị (VNĐ):</span>
								</Label>
								<Input
									type="number"
									value={vattu?.gia ?? ''}
									disabled
								/>
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={tt} alt="tt" />
									<span>Thuộc tính:</span>
								</Label>
								{vattu?.thuoctinh &&
									!vattu?.thuoctinh.length && (
										<div>Không có</div>
									)}
								{vattu?.thuoctinh &&
									vattu?.thuoctinh.map((item, key) => (
										<div key={key} className="row mt-3">
											<div className="col-4">
												<FormGroup
													style={{ marginBottom: 0 }}
												>
													<Input
														type="text"
														value={item.ten}
														disabled
													/>
												</FormGroup>
											</div>
											<div className="col-8">
												<Input
													type="text"
													value={item.giatri}
													disabled
												/>
											</div>
										</div>
									))}
							</FormGroup>
						</FormContent>
					</Form>
				</Content>
			</Container>

			<DialogMaterial
				open={open}
				onClose={handleClose}
				title="Xóa vật tư?"
				text2="Xóa"
				text1="Hủy"
				content=" Bạn chắc xóa vật tư này chứ ?"
				onClick2={handleDelete}
				onClick1={handleClose}
			/>
		</>
	);
};

export default VattuChitiet;
