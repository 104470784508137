import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Dashboard from '../../phanquyen/giamsatvien/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import RouteProvider from '../RouteProvider';
import NotfoundPage from '../../components/NotfoundPage';

const GiamsatvienRoutes = () => {
	return (
		<Router>
			<Switch>
				<Route
					exact
					path="/"
					component={RouteProvider}
				/>
				<ProtectedRoute
					exact
					path="/giamsatvien"
					component={Dashboard}
				/>
				<ProtectedRoute
					path="/giamsatvien/*"
					component={Dashboard}
				/>
				<Route
					path="*"
					component={NotfoundPage}
				/>
			</Switch>
		</Router>
	);
};

export default GiamsatvienRoutes;
