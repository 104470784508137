import React, { useRef } from 'react';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import {
	AddButton,
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';

import TableDonhang from './tables/TableDonhang';
import apiDonhang from '../../axios/apiDonhang';
import { headerDropdownLinks } from './arrayOfLinks';
import { StateContext } from '../../Context/StateContext';
import { useContext } from 'react';

import { useSearch } from '../../hooks/useSearch';

const Donhang = (props) => {
	const { queryText, setQueryText, search } = useSearch(['ma']);
	const [loading, setLoading] = React.useState(false);
	const context = useContext(StateContext);

	const fetchDsSanpham = useRef(async () => {
		setLoading(true);
		const { donhang } = await apiDonhang.allDsDonhang();
		context.setDsDonhang(donhang);
		setLoading(false);
	});

	React.useEffect(() => {
		fetchDsSanpham.current();
	}, []);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Đơn hàng"
					arrOfLinks={headerDropdownLinks}
					vaitro="admin"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách đơn hàng</Title>
							<AddButton
								className="btn btn-primary"
								onClick={() => props.history.push('/admin/donhang/them')}
							>
								<span>Thêm</span>
								<i className="fas fa-plus-circle"></i>
							</AddButton>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tìm đơn hàng theo mã"
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
								/>
							</SearchBox>
						</Filter>

						<TableSection>
							<TableDonhang
								dsDonhang={search(context.dsDonhang)}
								onTableUpdated={() => {
									fetchDsSanpham.current();
								}}
							/>
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Donhang;
