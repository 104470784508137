import React, { useState } from 'react';
import { FormContent, FormGroup, Input, Label } from '../styledComponents';
import ImageListDialog from '../../../components/ImageListDialog';

const DefectDetailsInfo = ({ productList }) => {
	const [shouldShowImageDialog, showImageDialog] = useState(false);
	const [imageData, setImageData] = useState({
		description: '',
		images: [],
	});

	return (
		<>
			{productList?.length > 0 &&
				productList.map((productItem, productIndex) => {
					return productItem.chitietloi.length > 0 ? (
						<FormContent key={productIndex}>
							<h6>
								Chi tiết xác nhận: {productItem?.ma} -{' '}
								{productItem?.ten}
							</h6>

							{productItem.chitietloi.length > 0 ? (
								<FormGroup
									style={{
										marginBottom: 10,
									}}
								>
									<Label className="d-block">
										Chi tiết lỗi
									</Label>
								</FormGroup>
							) : null}

							{productItem.chitietloi.map(
								(defectItem, defectIndex) => {
									return (
										<div
											className="row mb-2"
											key={defectIndex}
										>
											<div className="col-lg-6">
												<Input
													value={defectItem.ghichu}
													disabled={true}
												/>
											</div>
											<div className="col-lg-6">
												<div className="d-flex align-items-center">
													<div>
														<Input
															value={
																defectItem.soluong
															}
															disabled={true}
														/>
													</div>
													<div className="ml-3">
														<span
															className="text-primary text-decoration-underline"
															onClick={() => {
																let images =
																	productList[
																		productIndex
																	]
																		.chitietloi[
																		defectIndex
																	].anhloi;
																let description =
																	productList[
																		productIndex
																	]
																		.chitietloi[
																		defectIndex
																	].ghichu;

																if (
																	images.length ===
																	0
																)
																	return;

																setImageData({
																	description,
																	images,
																});
																showImageDialog(
																	true
																);
															}}
														>
															Ảnh lỗi (
															{
																productList[
																	productIndex
																].chitietloi[
																	defectIndex
																].anhloi.length
															}
															)
														</span>
													</div>
												</div>
											</div>
										</div>
									);
								}
							)}
						</FormContent>
					) : null;
				})}

			<ImageListDialog
				shouldOpen={shouldShowImageDialog}
				onClose={() => showImageDialog(false)}
				imageData={imageData}
			/>
		</>
	);
};

export default DefectDetailsInfo;
