import { useState, useMemo } from 'react';
import { getComparator } from '../utils';

export function useTablePagination(itemList, options) {
	const mergedOptions = {
		sortDirection: 'asc',
		sortBy: '',
		rowsPerPage: 20,
		...options,
	};
	const [sortDirection, setSortDirection] = useState(
		mergedOptions.sortDirection
	);
	const [sortBy, setSortBy] = useState(mergedOptions.sortBy);
	const [selectedItems, setSelectedItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(mergedOptions.rowsPerPage);

	const handleSortRequest = (event, property) => {
		const isAsc = sortBy === property && sortDirection === 'asc';
		setSortDirection(isAsc ? 'desc' : 'asc');
		setSortBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			setSelectedItems(itemList?.map((item) => item._id));
			return;
		}
		setSelectedItems([]);
	};

	const handleRowClick = (event, _id) => {
		const selectedIndex = selectedItems.indexOf(_id);
		let selected = [];

		if (selectedIndex === -1) {
			selected = selected.concat(selectedItems, _id);
		} else if (selectedIndex === 0) {
			selected = selected.concat(selectedItems.slice(1));
		} else if (selectedIndex === selectedItems.length - 1) {
			selected = selected.concat(selectedItems.slice(0, -1));
		} else if (selectedIndex > 0) {
			selected = selected.concat(
				selectedItems.slice(0, selectedIndex),
				selectedItems.slice(selectedIndex + 1)
			);
		}

		setSelectedItems(selected);
	};

	const handleChangePage = (event, newPage) => {
		setCurrentPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		setCurrentPage(0);
	};

	const isSelected = (_id) => selectedItems.indexOf(_id) !== -1;

	const emptyRows = useMemo(() => {
		return currentPage > 0
			? Math.max(0, (1 + currentPage) * rowsPerPage - itemList?.length)
			: 0;
	}, [currentPage, rowsPerPage, itemList?.length]);

	const sortedItems = useMemo(() => {
		return itemList
			?.slice()
			.sort(getComparator(sortDirection, sortBy))
			.slice(
				rowsPerPage > 0 ? currentPage * rowsPerPage : 0,
				rowsPerPage > 0
					? currentPage * rowsPerPage + rowsPerPage
					: itemList?.length
			);
	}, [currentPage, rowsPerPage, itemList, sortDirection, sortBy]);

	return {
		sortDirection,
		sortBy,
		selectedItems,
		currentPage,
		rowsPerPage,
		handleSortRequest,
		handleSelectAllClick,
		handleRowClick,
		handleChangePage,
		handleChangeRowsPerPage,
		isSelected,
		emptyRows,
		sortedItems,
	};
}
