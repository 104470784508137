function SaveButton({ onClick }) {
	return (
		<button className="btn btn-primary px-3" onClick={onClick}>
			Lưu
			<i className="ml-2 fas fa-save"></i>
		</button>
	);
}

export default SaveButton;
