import React from 'react';
import TableSanpham from './tables/TableSanpham';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import {
	AddButton,
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';

import apiSanpham from '../../axios/apiSanpham';
import apiDonhang from '../../axios/apiDonhang';
import { headerDropdownLinks } from './arrayOfLinks';

import { useSearch } from '../../hooks/useSearch';

const Sanpham = (props) => {
	const { queryText, setQueryText, search } = useSearch(['ma', 'ten']);

	const [loading, setLoading] = React.useState(false);
	const [dsSanpham, setDsSanpham] = React.useState([]);
	const [dsSanphamDangDung, setDsSanphamDangDung] = React.useState([]);
	const [rowsRemoved, setRowsRemoved] = React.useState(false);

	React.useEffect(() => {
		setRowsRemoved(false);

		let ignore = false;
		const fetchDsSanpham = async () => {
			setLoading(true);
			const { sanpham } = await apiSanpham.dsSanpham();
			const { donhang } = await apiDonhang.allDsDonhang();

			let sanPhamIds = [];
			donhang.forEach((dh) => {
				dh.dssanpham?.forEach((sp) => {
					if (sp && sp.sanpham?._id) {
						sanPhamIds.push(sp.sanpham?._id);
					}
				});
			});

			if (ignore) return;

			setDsSanphamDangDung(sanPhamIds);
			setDsSanpham(sanpham);
			setLoading(false);
		};
		fetchDsSanpham();
		return () => (ignore = true);
	}, [rowsRemoved]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Sản phẩm"
					arrOfLinks={headerDropdownLinks}
					vaitro="admin"
				/>

				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Tất cả sản phẩm</Title>
							<AddButton
								className="btn btn-primary"
								onClick={() => props.history.push('/admin/sanpham/them')}
							>
								<span>Thêm</span>
								<i className="fas fa-plus-circle"></i>
							</AddButton>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tìm sản phẩm theo mã, tên"
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
								/>
							</SearchBox>
						</Filter>

						<TableSection>
							<TableSanpham
								dsSanpham={search(dsSanpham)}
								dsSanphamDangDung={dsSanphamDangDung}
								setRowsRemoved={setRowsRemoved}
							/>
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Sanpham;
