import React, { useEffect, useState } from 'react';
import TableDaily2 from './tables/TableDaily2';
import BackdropMaterial from '../../components/BackdropMaterial';
import { useSelector } from 'react-redux';
import {
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import Header from '../../components/Header';
import apiBophankd from '../../axios/apiBophankd';
import { headerDropdownLinks } from './arrayOfLinks';

import { useSearch } from '../../hooks/useSearch';

const Daily2 = (props) => {
	const { queryText, setQueryText, search } = useSearch([
		'ten',
		'sdt',
		'email',
		'taikhoan',
	]);

	const [dsDaily2, setDsDaily2] = useState([]);
	const [loading, setLoading] = useState(false);
	const { userInfo } = useSelector((state) => state.user);

	useEffect(() => {
		let isMounted = true;

		const fetchData = async () => {
			setLoading(true);
			const { bophankd } = await apiBophankd.bophankdReadOnlyUserId(
				userInfo._id
			);
			const { daily2 } = await apiBophankd.dsDaily2(bophankd._id);

			if (!isMounted) return;

			setDsDaily2(daily2 && daily2.length ? daily2 : []);
			setLoading(false);
		};
		fetchData();

		return () => {
			isMounted = false;
		};
	}, [userInfo._id]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Đại lý cấp 2"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvien"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách đại lý cấp 2</Title>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tìm đại lý theo tên, số điện thoại, email và tài khoản"
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
								/>
							</SearchBox>
						</Filter>

						<TableSection className="noCheckbox">
							<TableDaily2 dsDaily2={search(dsDaily2)} />
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Daily2;
