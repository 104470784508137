import React, { useEffect, useState } from 'react';
import TableNguyenlieu from './tables/TableNguyenlieu';
import { useSelector } from 'react-redux';
import apiBophankd from '../../axios/apiBophankd';
import BackdropMaterial from '../../components/BackdropMaterial';
import {
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import Header from '../../components/Header';
import { headerDropdownLinks } from './arrayOfLinks';
import { getCurrentDate, thisMonth, thisYear } from '../../utils';
import DialogMaterial from '../../components/DialogMaterial';
import Thongke from '../../components/Thongke';

import { useSearch } from '../../hooks/useSearch';

const Nguyenlieu = (props) => {
	const { queryText, setQueryText, search } = useSearch(['ten', 'madonhang']);

	const [loading, setLoading] = useState(false);
	const [dsNguyenlieu, setDsNguyenlieu] = useState([]);
	const { userInfo } = useSelector((state) => state.user);

	const [alert, setAlert] = React.useState(false);
	const [alertMsg, setAlertMsg] = React.useState(false);
	const [dsThongke, setdsThongke] = React.useState([]);
	const [thongkeType, setThongkeType] = useState('');
	const [dateRange, setDateRange] = React.useState({
		from: '',
		to: '',
	});

	const handleClickThang = () => {
		setThongkeType('thang');
		const { firstDay, lastDay } = thisMonth();
		let dsngl = [];
		for (const ngl of dsNguyenlieu) {
			const ngtao = ngl.ngaytao;
			if (ngtao >= firstDay && ngtao <= lastDay) {
				dsngl = [ngl, ...dsngl];
			}
		}
		setdsThongke(dsngl);
	};

	const handleClickNam = () => {
		setThongkeType('nam');
		const { firstDay, lastDay } = thisYear();
		let dsngl = [];
		for (const ngl of dsNguyenlieu) {
			const ngtao = ngl.ngaytao;
			if (ngtao >= firstDay && ngtao <= lastDay) {
				dsngl = [ngl, ...dsngl];
			}
		}
		setdsThongke(dsngl);
	};

	const handleClickThongke = () => {
		if (validateDate()) {
			setThongkeType('range');
			const startDay = new Date(dateRange.from);
			const endDay = new Date(dateRange.to);
			let dsngl = [];
			for (const ngl of dsNguyenlieu) {
				const ngtao = new Date(ngl.ngaytao);
				if (ngtao >= startDay && ngtao <= endDay) {
					dsngl = [ngl, ...dsngl];
				}
			}
			setdsThongke(dsngl);
		}
	};

	const validateDate = () => {
		const from = new Date(dateRange.from);
		const to = new Date(dateRange.to);
		const today = new Date(getCurrentDate());
		if (!dateRange.from) {
			setAlertMsg('Vui lòng nhập ngày bắt đầu');
			handleOpen();
			return false;
		}
		if (from > today) {
			setAlertMsg('Ngày bắt đầu không hợp lệ');
			handleOpen();
			return false;
		}
		if (!dateRange.to) {
			setAlertMsg('Vui lòng nhập ngày kết thúc');
			handleOpen();
			return false;
		}
		if (from > to || to < from) {
			setAlertMsg('Ngày bắt đầu vượt quá ngày kết thúc');
			handleOpen();
			return false;
		}
		return true;
	};

	const handleOpen = () => setAlert(true);
	const handleClose = () => setAlert(false);

	const handleChangeDateFrom = (e) => {
		setDateRange({ ...dateRange, from: e.target.value });
	};

	const handleChangeDateTo = (e) => {
		setDateRange({ ...dateRange, to: e.target.value });
	};

	useEffect(() => {
		let isMounted = true;
		const fetchDsNguyenlieu = async () => {
			setLoading(true);
			const { bophankd } = await apiBophankd.bophankdReadOnlyUserId(
				userInfo._id
			);
			let { dsnguyenlieu } = await apiBophankd.bophankdDSNguyenlieu(
				bophankd._id
			);
			dsnguyenlieu = dsnguyenlieu.map((ngl) => ({
				...ngl.nguyenlieu,
				...ngl,
				madonhang: ngl.donhang.ma,
			}));

			if (!isMounted) return;

			setDsNguyenlieu(dsnguyenlieu);
			setLoading(false);
		};
		fetchDsNguyenlieu();

		return () => {
			isMounted = false;
		};
	}, [userInfo._id]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Nguyên liệu"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvien"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách nguyên liệu</Title>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tim nguyên liệu theo tên, công dụng"
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
								/>
							</SearchBox>
						</Filter>

						<TableSection className="noCheckbox">
							<TableNguyenlieu dsNguyenlieu={search(dsNguyenlieu)} />
						</TableSection>
					</FilterSection>

					<Thongke
						onClickThang={handleClickThang}
						onClickNam={handleClickNam}
						onClickThongke={handleClickThongke}
						handleChangeDateFrom={handleChangeDateFrom}
						handleChangeDateTo={handleChangeDateTo}
						fromDate={dateRange.from}
						toDate={dateRange.to}
						dsNguyenlieu={dsThongke}
						thongkeType={thongkeType}
					/>
				</Content>
			</Container>

			<DialogMaterial
				open={alert}
				onClose={handleClose}
				title="Lỗi"
				content={alertMsg}
				text2="OK"
				onClick2={handleClose}
			/>
		</>
	);
};

export default Nguyenlieu;
