import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	image: {
		width: '500px',
		height: '400px',
		objectFit: 'contain',
		objectPosition: 'center',
	},
}));

const ImageListDialog = ({ imageData, onClose, shouldOpen }) => {
	const classes = useStyles();

	let images = imageData.images.map((item) => {
		return `/uploads/${item}`;
	});

	return (
		<Dialog
			open={shouldOpen}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Hình ảnh lỗi sản phẩm
			</DialogTitle>
			<DialogContent style={{ minWidth: 380 }}>
				<DialogContentText id="alert-dialog-description">
					{imageData.description}
				</DialogContentText>
				<div className={classes.root}>
					{images.map((item) => (
						<img
							className={classes.image}
							key={item}
							src={item}
							alt=""
						/>
					))}
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onClose}
					color="primary"
					style={{ outline: 'none' }}
				>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImageListDialog;
