import { Alert } from '../styledComponents';

const RemainingQuantityAlert = (props) => {
	return (
		<Alert className={props.className}>
			<div
				className="title"
				style={{
					marginTop: -20,
				}}
			>
				<span>Số lượng chưa phân phát của đơn hàng</span>
			</div>
			<div className="content">
				{props.products?.map((product) => (
					<p key={product.sanpham._id ?? ''}>
						{`${product?.sanpham.ten} (${product?.sanpham.ma})`}:
						<span>{product?.remainingQuantity ?? 0}</span>
					</p>
				))}
			</div>
		</Alert>
	);
};

export default RemainingQuantityAlert;
