import apiGiaohang from '../../axios/apiGiaohang';
import BasePageComponent from '../shared/giaohang/HanggiaodenChitiet';

const HanggiaodenChitiet = (props) => {
	const { id } = props.match.params;

	return (
		<BasePageComponent
			deliveryId={id}
			apiConfirmDelivery={apiGiaohang.bophankdXacnhan}
			getSenderInfo={(giaohang) => giaohang?.giamsatvung}
			vaitro="bophankd"
		/>
	);
};

export default HanggiaodenChitiet;
