export const headCellsLangnghe = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên',
	},
	{
		id: 'tinh',
		numeric: false,
		disablePadding: false,
		label: 'Tỉnh',
	},
	{
		id: 'huyen',
		numeric: false,
		disablePadding: false,
		label: 'Huyện',
	},
	{
		id: 'sanphamchinh',
		numeric: false,
		disablePadding: false,
		label: 'Sản phẩm chính',
	},
];

export const headCellsHodan = [
	{
		id: 'daidien',
		numeric: false,
		disablePadding: true,
		label: 'Đại diện',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'cmnd',
		numeric: false,
		disablePadding: false,
		label: 'CMND',
	},

	{
		id: 'namsinh',
		numeric: true,
		disablePadding: false,
		label: 'Năm sinh',
	},
	{
		id: 'nghe',
		numeric: false,
		disablePadding: false,
		label: 'Nghề',
	},
	{
		id: 'langnghe',
		numeric: false,
		disablePadding: false,
		label: 'Làng nghề',
	},
];

export const headCellsHodan2 = [
	{
		id: 'daidien',
		numeric: false,
		disablePadding: true,
		label: 'Đại diện',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: true,
		label: 'Số điện thoại',
	},
	// {
	// 	id: 'cmnd',
	// 	numeric: false,
	// 	disablePadding: true,
	// 	label: 'CMND',
	// },
	{
		id: 'namsinh',
		numeric: true,
		disablePadding: true,
		label: 'Năm sinh',
	},
	{
		id: 'loaisp',
		numeric: false,
		disablePadding: true,
		label: 'Loại sản phẩm',
	},
	{
		id: 'langnghe',
		numeric: false,
		disablePadding: true,
		label: 'Làng nghề',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: true,
		label: 'Trạng thái',
	},
];

export const headCellsDaily1 = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: false,
		label: 'Trạng thái',
	},
];

export const headCellsDaily2 = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên đại lý',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: false,
		label: 'Trạng thái',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: false,
		label: '',
	},
];

export const headCellsDonhang = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'tongsanpham',
		numeric: false,
		disablePadding: false,
		label: 'Tổng sản phẩm',
	},
	{
		id: 'tongcongcu',
		numeric: true,
		disablePadding: false,
		label: 'Tổng công cụ',
	},
	{
		id: 'tongvattu',
		numeric: false,
		disablePadding: false,
		label: 'Tổng vật tư',
	},
	{
		id: 'tongnguyenlieu',
		numeric: false,
		disablePadding: false,
		label: 'Tổng nguyên liệu',
	},
	// {
	// 	id: 'tonggia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Tổng giá (VNĐ)',
	// },
	{
		id: 'ngayppden',
		numeric: false,
		disablePadding: false,
		label: 'Phân phát đến',
	},
	{
		id: 'phanphatdi',
		numeric: false,
		disablePadding: false,
		label: 'Phân phát đi',
	},
];

export const headCellsCongcuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
];

export const headCellsNguyenlieuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'khoiluong',
		numeric: false,
		disablePadding: false,
		label: 'Khối lượng',
	},
];

export const headCellsSanphamDonhangChitiet = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'soluonght',
		numeric: false,
		disablePadding: false,
		label: 'Hộ dân hoàn thành',
	},
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'dagiao',
		numeric: false,
		disablePadding: false,
		label: 'Đã giao',
	},
	{
		id: 'conlai',
		numeric: false,
		disablePadding: false,
		label: 'Còn lại',
	},
	// {
	// 	id: 'dongia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Đơn giá (VNĐ)',
	// },
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
];

export const headCellsSanphamDonhangChitiet2 = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'soluonght',
		numeric: false,
		disablePadding: false,
		label: 'Hộ dân hoàn thành',
	},
	{
		id: 'dagiao',
		numeric: false,
		disablePadding: false,
		label: 'Đã giao',
	},
	{
		id: 'conlai',
		numeric: false,
		disablePadding: false,
		label: 'Còn lại',
	},
	// {
	// 	id: 'dongia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Đơn giá (VNĐ)',
	// },
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
];

export const headCellsVattuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
];

export const headCellsDonhangGoc = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: true,
		disablePadding: false,
		label: 'Tên',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	// {
	// 	id: 'dongia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Đơn giá (VNĐ)',
	// },
];

export const headCellsSanphamDonhang = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	// {
	// 	id: 'dongia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Đơn giá (VNĐ)',
	// },
	// {
	// 	id: 'tonggia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Tổng giá (VNĐ)',
	// },
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
];

export const headCellsCongcu = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Tổng số lượng',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsCongcuHuloi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsNguyenlieu = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'khoiluong',
		numeric: true,
		disablePadding: false,
		label: 'Tổng khối lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	// {
	//   id: "donvitinh",
	//   numeric: false,
	//   disablePadding: false,
	//   label: "Đơn vị tính",
	// },
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsNguyenlieuHuloi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'khoiluong',
		numeric: true,
		disablePadding: false,
		label: 'Khối lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	// {
	//   id: "donvitinh",
	//   numeric: false,
	//   disablePadding: false,
	//   label: "Đơn vị tính",
	// },
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsVattu = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Tổng số lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: true,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsVattuHuloi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: true,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsSanpham = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'masanpham',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Tổng số lượng',
	},
	{
		id: 'soluonghoanthanh',
		numeric: false,
		disablePadding: false,
		label: 'Hộ dân hoàn thành',
	},
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'dagiao',
		numeric: false,
		disablePadding: false,
		label: 'Đã giao đi',
	},
	{
		id: 'conlai',
		numeric: false,
		disablePadding: false,
		label: 'Còn lại',
	},
	{
		id: 'qrcode',
		numeric: false,
		disablePadding: false,
		label: 'QR Code',
	},
	// {
	// 	id: 'tonggia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Tổng giá (VNĐ)',
	// },
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
	// {
	//   id: "ngaytao",
	//   numeric: false,
	//   disablePadding: false,
	//   label: "Ngày tạo",
	// },
];

export const headCellsGiaohang = [
	{
		id: 'masanpham',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	// {
	//   id: "hinhanh",
	//   numeric: false,
	//   disablePadding: true,
	//   label: "Hình ảnh",
	// },
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng thực hiện',
	},
	{
		id: 'hodanhoanthanh',
		numeric: false,
		disablePadding: false,
		label: 'Hộ dân hoàn thành',
	},
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'dagiaodi',
		numeric: false,
		disablePadding: false,
		label: 'Đã giao đi',
	},
	{
		id: 'conlai',
		numeric: false,
		disablePadding: false,
		label: 'Còn lại',
	},
	{
		id: 'soluonggiao',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng giao',
	},
];

export const headCellsGiaohangDi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'bpkd',
		numeric: false,
		disablePadding: true,
		label: 'Bộ phận kinh doanh',
	},
	{
		id: 'tongsanpham',
		numeric: false,
		disablePadding: false,
		label: 'Tổng sản phẩm',
	},
	{
		id: 'ngaygiao',
		numeric: false,
		disablePadding: true,
		label: 'Ngày giao đi',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: true,
		label: 'Trạng thái',
	},
	{
		id: 'ngayxacnhan',
		numeric: false,
		disablePadding: true,
		label: 'Ngày xác nhận',
	},
];

export const headCellsHanggiaodenChitiet = [
	{
		id: 'masp',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'tensp',
		numeric: false,
		disablePadding: true,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'slthuchien',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng thực hiện',
	},
	// {
	// 	id: 'slhoanthanh',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Hộ dân hoàn thành',
	// },
	{
		id: 'sldanhan',
		numeric: false,
		disablePadding: false,
		label: 'Đại lý 1 đã nhận',
	},
	{
		id: 'soluonggiao',
		numeric: false,
		disablePadding: false,
		label: 'Số luợng giao',
	},
];

export const headCellsGiaohangDen = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'daily1',
		numeric: false,
		disablePadding: true,
		label: 'Đại lý 1',
	},
	{
		id: 'tongsanpham',
		numeric: false,
		disablePadding: false,
		label: 'Tổng sản phẩm',
	},
	{
		id: 'ngaygiao',
		numeric: false,
		disablePadding: true,
		label: 'Ngày giao đến',
	},
	{
		id: 'xacnhan',
		numeric: false,
		disablePadding: true,
		label: 'Ngày xác nhận',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: true,
		label: '',
	},
];

export const headCellsHanggiaodiChitiet = [
	{
		id: 'masp',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'tensp',
		numeric: false,
		disablePadding: true,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'slthuchien',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng thực hiện',
	},
	// {
	// 	id: 'slhoanthanh',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Hộ dân hoàn thành',
	// },
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'soluonggiao',
		numeric: false,
		disablePadding: false,
		label: 'Số luợng giao',
	},
];
