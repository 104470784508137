import { useEffect, useState } from 'react';

export function useDsGiaohang(fetcher) {
	const [isLoading, setLoading] = useState(false);
	const [dsGiaohang, setDsGiaohang] = useState([]);

	useEffect(() => {
		let ignore = false;

		(async () => {
			setLoading(true);
			let dsgiaohang = await fetcher();
			dsgiaohang = dsgiaohang
				.map((item) => ({
					...item,
					madonhang: item.donhang.ma,
					tongsanpham: item.dssanpham.reduce(
						(acc, sp) => acc + sp.dagiao,
						0
					),
				}))
				.reverse();

			if (ignore) return null;

			setDsGiaohang(dsgiaohang, setLoading(false));
		})();
		return () => {
			ignore = true;
		};
	}, [fetcher]);

	return {
		isLoading,
		dsGiaohang,
	};
}
