import React, { useEffect, useState, useRef } from 'react';
import {
	Container,
	Content,
	ErrMsg,
	Form,
	FormContent,
	FormGroup,
	FormTitle,
	Label,
	TableSection,
	TableTitle,
	Total,
	TotalValue,
} from './styledComponents';
import Header from '../../components/Header';
import { toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import BackdropMaterial from '../../components/BackdropMaterial';
import apiDonhang from '../../axios/apiDonhang';
import apiBophankd from '../../axios/apiBophankd';
import { useSelector } from 'react-redux';
import DropdownMaterial2 from '../../components/DropdownMaterial2';
import TableSanphamDonhang from './tables/TableSanphamDonhang';
import TableDonhangGoc from './tables/TableDonhangGoc';
import {
	formatMoney,
	getDsNguyenVatlieu,
	getTongNguyenVatlieu,
} from '../../utils';
import MultipleSelect from '../../components/MultipleSelect';
import ma from '../../assets/icons/ma.png';
import _gsv from '../../assets/icons/gsv_2.png';
import dhgoc from '../../assets/icons/dhgoc.png';
import DialogMaterial from '../../components/DialogMaterial';
import ConfirmationDialogMaterial from '../../components/ConfirmationDialogMaterial';
import SaveButton from '../../components/SaveButton';

import RemainingQuantityAlert from '../shared/donhang/RemainingQuantityAlert';
import TableDonhangPhanphat from '../shared/donhang/TableDonhangPhanphat';

const DonhangThem = (props) => {
	const [isLoading, setLoading] = useState(false);
	const { userInfo } = useSelector((state) => state.user);

	const [shouldOpenErrorDialog, setShouldOpenErrorDialog] = useState(false);
	const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] =
		useState(false);
	const [errMsg, setErrMsg] = useState('');
	const openErrorDialog = () => setShouldOpenErrorDialog(true);
	const closeErrorDialog = () => setShouldOpenErrorDialog(false);
	const openConfirmationDialog = () => setShouldOpenConfirmationDialog(true);
	const closeConfirmationDialog = () =>
		setShouldOpenConfirmationDialog(false);

	const [orderCollections, setOrderCollections] = useState({
		originalOrders: [],
		from: null,
		destinationList: [],
	});

	const [selectedOrderId, setSelectedOrderId] = useState(null);
	const [selectedDestinationList, setSelectedDestinationList] = useState([]);
	const [subOrders, setSubOrders] = useState([
		{
			to: null,
			products: [],
		},
	]);

	let selectedOrderInfoRef = useRef({
		selectedOrder: null,
		selectedOrderValue: 0,
		pastSubOrders: [],
		pastSubOrderQuantities: new Map(),
	});

	// Tim order duoc chon de phan phat
	function getSelectedOrder(orderId) {
		return orderCollections.originalOrders.find(
			(order) => order._id === orderId
		);
	}

	// Tim cac suborder da dat truoc do cua order duoc chon
	function getPastSubOrders(order) {
		return order?.subdonhang.reduce((acc, sub) => {
			let products = sub.dssanpham.map((product) => {
				let toId = Object.values(sub.to)[0];
				let to = orderCollections.destinationList.find(
					(dest) => dest._id === toId
				);
				return {
					...product,
					to: {
						_id: toId,
						ten: to.ten,
					},
					bihuy: sub.bihuy,
					xacnhan: sub.xacnhan,
					ten: product.sanpham.ten,
					ma: product.sanpham.ma,
					hinhanh: product.sanpham.hinhanh,
				};
			});
			return [...acc, ...products];
		}, []);
	}

	// Tinh so luong tung san pham trong cac suborder da dat truoc do
	function getPastSubOrderQuantities(subOrders) {
		return subOrders?.reduce((acc, sub) => {
			if (!acc.get(sub.sanpham._id)) {
				acc.set(sub.sanpham._id, 0);
			}
			if (!sub.bihuy) {
				acc.set(
					sub.sanpham._id,
					acc.get(sub.sanpham._id) + sub.soluong
				);
			}
			return acc;
		}, new Map());
	}

	// Ham tinh tong gia tri don hang dua tren cac san pham da dat
	const getOrderValue = (productsOrdered) => {
		let orderValue = productsOrdered?.reduce((acc, item) => {
			return acc + item.soluong * item.gia;
		}, 0);
		return orderValue ?? 0;
	};

	// Tinh so luong tung san pham trong cac suborder dang phan phat
	const subOrderQuantities = subOrders?.reduce((acc, sub) => {
		sub.products.forEach((product) => {
			if (!acc.get(product._id)) {
				acc.set(product._id, 0);
			}
			acc.set(product._id, acc.get(product._id) + product.soluong);
		});
		return acc;
	}, new Map());

	// console.log('subOrderQuantities', subOrderQuantities);

	// Ham tinh so luong san pham dang phan phat theo tung noi den
	const getSubOrderQuantityOfProductByToId = (productId, toId) => {
		let subOrderQuantity = subOrders
			?.find((subOrder) => subOrder.to._id === toId)
			?.products.find((product) => product._id === productId)?.soluong;
		return subOrderQuantity ?? 0;
	};

	// Kiem tra xem so luong san pham trong cac suborder co vuot qua don hang goc hay khong
	const isSubOrderQuantityExceeded = (orderedQuantity, productId, toId) => {
		let { pastSubOrderQuantities, selectedOrder } =
			selectedOrderInfoRef.current;

		const originalOrder = selectedOrder?.dssanpham.find(
			(sp) => sp.sanpham._id === productId
		);

		let pastQuantity = pastSubOrderQuantities?.get(productId) ?? 0;
		let currentQuantity = subOrderQuantities?.get(productId) ?? 0;
		let currentQuantityByToId = getSubOrderQuantityOfProductByToId(
			productId,
			toId
		);
		let newOrderQuantity =
			pastQuantity +
			currentQuantity -
			currentQuantityByToId +
			orderedQuantity;

		if (newOrderQuantity > originalOrder.soluong) {
			let msg = `Tổng số lượng sản phẩm "${
				originalOrder.sanpham.ma
			}" vượt quá ${
				newOrderQuantity - originalOrder.soluong
			} so với số lượng gốc ${originalOrder.soluong}`;
			setErrMsg(msg);
			openErrorDialog();
			return true;
		}
		return false;
	};

	// Ham xu ly khi thay doi so luong san pham trong cac suborder
	const handleProductOrderedChanges = (toId, productOrdered) => {
		let order = {
			...productOrdered,
			quantity:
				productOrdered.quantity === ''
					? 0
					: parseInt(productOrdered.quantity),
		};
		// console.log('handleProductOrderedChanges', order);
		if (
			!isSubOrderQuantityExceeded(order.quantity, order.productId, toId)
		) {
			const { productId, quantity, unitPrice, dueDate } = order;
			setSubOrders(
				subOrders.map((subOrder) =>
					subOrder.to._id === toId
						? {
								...subOrder,
								products: subOrder.products.map((product) =>
									product._id === productId
										? {
												...product,
												soluong: quantity,
												gia: unitPrice,
												thoihan: dueDate,
										  }
										: product
								),
						  }
						: subOrder
				)
			);
		}
	};

	// Ham xu ly khi thay doi danh sach noi den
	const handleToListIdsChanges = (e) => {
		const {
			target: { value },
		} = e;

		// console.log('handleToListIdsChanges', value);

		let newDestList = typeof value === 'string' ? value.split(',') : value;
		setSelectedDestinationList(newDestList);

		let { pastSubOrderQuantities } = selectedOrderInfoRef.current;

		// Khoi tao gia tri ban dau cho cac don hang
		let localSubOrders =
			subOrders.length > 1
				? subOrders.map((subOrder) => {
						let soluong = newDestList.includes(subOrder.to._id)
							? 1
							: 0;
						return {
							...subOrder,
							products: subOrder.products.map((product) => ({
								...product,
								soluong,
							})),
						};
				  })
				: subOrders.map((item) => ({
						...item,
						products: item.products.map((product) => ({
							...product,
							soluong:
								product.soluonggoc -
								(pastSubOrderQuantities?.get(product._id) ?? 0),
						})),
				  }));
		setSubOrders(localSubOrders);
	};

	// Ham xu ly khi don hang goc thay doi
	const handleSelectedOrderChanges = (e) => {
		const { value } = e.target;
		// console.log('handleSelectedOrderChanges', value);
		setSelectedOrderId(value);
		setSelectedDestinationList([]);

		let selectedOrder = getSelectedOrder(value);
		let pastSubOrders = getPastSubOrders(selectedOrder);
		let pastSubOrderQuantities = getPastSubOrderQuantities(pastSubOrders);
		const selectedOrderValue = selectedOrder?.tongdongia ?? 0;

		selectedOrderInfoRef.current = {
			selectedOrder,
			selectedOrderValue,
			pastSubOrders,
			pastSubOrderQuantities,
		};
		initSubOrders(value);
	};

	// Khoi tao danh sach cac don hang con co the ung voi don hang goc da chon
	const initSubOrders = (orderId) => {
		let { pastSubOrderQuantities } = selectedOrderInfoRef.current;
		const originalOrder = orderCollections.originalOrders.find(
			(order) => order._id === orderId
		);
		let localSubOrders = [
			{
				to: null,
				products: [],
			},
		];
		orderCollections.destinationList.forEach((to) => {
			localSubOrders = [
				{
					to: {
						_id: to._id,
						ten: to.ten,
					},
					products: [],
				},
				...localSubOrders,
			];
			originalOrder.dssanpham.forEach((product) => {
				if (to.loaisanpham.includes(product.sanpham.loaisanpham)) {
					localSubOrders = localSubOrders.map((subOrder) =>
						subOrder.to !== null && subOrder.to._id === to._id
							? {
									...subOrder,
									products: [
										...subOrder.products,
										{
											...product.sanpham,
											soluonggoc: product.soluong,
											soluong: 0,
											gia:
												product.gia ??
												product.sanpham.gia,
											thoihan: product.thoihan,
										},
									],
							  }
							: subOrder
					);
				}
			});
		});

		// Loc bo cac san pham da dat day du so luong
		localSubOrders = localSubOrders.map((subOrder) => {
			return {
				...subOrder,
				products: subOrder.products.filter((product) => {
					return (
						product.soluonggoc >
						(pastSubOrderQuantities?.get(product._id) ?? 0)
					);
				}),
			};
		});

		// Loc bo cac don hang khong co san pham
		localSubOrders = localSubOrders.filter(
			(item) => item.to !== null && item.products.length > 0
		);

		// console.log('initSubOrders', localSubOrders);
		setSubOrders(localSubOrders);
	};

	const emptyFields = () => {
		if (!selectedOrderId || !selectedDestinationList.length) {
			setErrMsg('Thông tin không được để trống');
			return true;
		}
		return false;
	};

	const handleSubmitSubOrders = async () => {
		if (!emptyFields()) {
			openConfirmationDialog();
		}
	};

	const submitSubOrders = async () => {
		let subOrdersForApi = [];
		let { selectedOrder } = selectedOrderInfoRef.current;

		subOrders
			.map((sub) => {
				return {
					...sub,
					products: sub.products.filter(
						(product) => product.soluong > 0
					),
				};
			})
			.filter((sub) => sub.products.length > 0)
			.forEach((subOrder) => {
				if (selectedDestinationList.includes(subOrder.to._id)) {
					const {
						danhsachcongcu,
						danhsachvattu,
						danhsachnguyenlieu,
					} = getDsNguyenVatlieu(subOrder.products);
					let subOrderForApi = {
						ma: selectedOrder.ma,
						dssanpham: subOrder.products.map((product) => ({
							sanpham: product._id,
							soluong: product.soluong,
							gia: product.gia,
							thoihan: product.thoihan,
							soluonghoanthanh: 0,
						})),
						tongsanpham: getTongNguyenVatlieu(
							subOrder.products,
							'sanpham'
						),
						dscongcu: danhsachcongcu.map((item) => ({
							congcu: item.congcu._id,
							soluong: item.soluong,
						})),
						tongcongcu: getTongNguyenVatlieu(
							danhsachcongcu,
							'congcu'
						),
						dsvattu: danhsachvattu.map((item) => ({
							vattu: item.vattu._id,
							soluong: item.soluong,
						})),
						tongvattu: getTongNguyenVatlieu(danhsachvattu, 'vattu'),
						dsnguyenlieu: danhsachnguyenlieu.map((item) => ({
							nguyenlieu: item.nguyenlieu._id,
							khoiluong: item.khoiluong,
						})),
						tongnguyenlieu: getTongNguyenVatlieu(
							danhsachnguyenlieu,
							'nguyenlieu'
						),
						tongdongia: getOrderValue(subOrder.products),
						from: {
							bophankd: orderCollections.from._id,
						},
						to: {
							giamsatvung: subOrder.to._id,
						},
					};
					subOrdersForApi.push(subOrderForApi);
				}
			});
		const { success, savedDonhang } = await apiDonhang.bophankdToGsv({
			donhangId: selectedOrderId,
			dsdonhang: subOrdersForApi,
			bophankdId: orderCollections.from._id,
		});
		if (success) {
			toast.success('Thêm thành công!', { theme: 'colored' });
			props.history.push(
				`/bophankd/donhang/chitiet/${savedDonhang._id}/tiendo`
			);
		}
	};

	// Tinh so luong san pham con lai chua phan phat
	const remainingProductQuantities =
		selectedOrderInfoRef.current.selectedOrder?.dssanpham
			.sort((i, j) => i.sanpham.ten.localeCompare(j.sanpham.ten))
			.map((product) => {
				let { pastSubOrderQuantities } = selectedOrderInfoRef.current;
				let productId = product.sanpham._id;
				let remainingQuantity =
					product.soluong -
					(pastSubOrderQuantities?.get(productId) ?? 0) -
					(subOrderQuantities.get(productId) ?? 0);
				return {
					...product,
					remainingQuantity,
				};
			});

	useEffect(() => {
		let ignore = false;
		(async () => {
			setLoading(true);
			const { donhang } = await apiDonhang.dsDonhang();
			const { bophankd } = await apiBophankd.bophankdBasedUserId(
				userInfo._id
			);
			const { giamsatvung } = await apiBophankd.bophankdDsGSV(
				bophankd._id
			);

			if (ignore) return;

			setOrderCollections({
				originalOrders: donhang,
				from: bophankd,
				destinationList: giamsatvung,
			});
			setLoading(false);
		})();
		return () => {
			ignore = true;
		};
	}, [userInfo._id]);

	if (isLoading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Quay lại danh sách đơn hàng"
					titleBack
					onClick={() => props.history.push('/bophankd/donhang')}
					headerRight={<SaveButton onClick={handleSubmitSubOrders} />}
				/>
				<Content>
					<Form>
						<FormContent>
							<FormTitle>
								<span>Phân phát đơn hàng</span>
							</FormTitle>

							<FormGroup>
								<Label>
									<img src={ma} alt="ma" />
									<span>Mã đơn hàng:</span>
								</Label>
								{orderCollections.originalOrders &&
								orderCollections.originalOrders.length ? (
									<DropdownMaterial2
										label="Chọn mã đơn hàng"
										value={selectedOrderId ?? ''}
										onChange={handleSelectedOrderChanges}
									>
										{orderCollections.originalOrders.map(
											(order) => (
												<MenuItem
													key={order.ma ?? ''}
													value={order._id ?? ''}
												>
													{order.ma}
												</MenuItem>
											)
										)}
									</DropdownMaterial2>
								) : (
									<DropdownMaterial2
										label="Chọn mã đơn hàng"
										value=""
									/>
								)}
								{!selectedOrderId && <ErrMsg>{errMsg}</ErrMsg>}
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={_gsv} alt="gsv" />
									<span>Giám sát vùng:</span>
								</Label>
								{subOrders && subOrders.length ? (
									<MultipleSelect
										label="Chọn gám sát vùng"
										value={selectedDestinationList ?? []}
										onChange={handleToListIdsChanges}
									>
										{subOrders.map((subOrder) => (
											<MenuItem
												key={subOrder?.to?._id ?? ''}
												value={subOrder?.to?._id ?? ''}
											>
												{subOrder?.to?.ten}
											</MenuItem>
										))}
									</MultipleSelect>
								) : (
									<MultipleSelect
										label="Chọn gám sát vùng"
										value={[]}
									/>
								)}
								{selectedDestinationList.length === 0 && (
									<ErrMsg>{errMsg}</ErrMsg>
								)}
							</FormGroup>
						</FormContent>

						<div className="px-5">
							{selectedOrderId ? (
								<TableSection className="noCheckbox">
									<TableTitle>
										<img src={dhgoc} alt="dhgoc" />
										<span>Đơn hàng gốc</span>
									</TableTitle>
									<TableDonhangGoc
										donhang={
											selectedOrderInfoRef.current
												.selectedOrder
										}
									/>
									<div className="text-right">
										<Total>Tổng đơn hàng:</Total>
										<TotalValue>
											{formatMoney(
												selectedOrderInfoRef.current
													.selectedOrderValue
											)}{' '}
											VNĐ
										</TotalValue>
									</div>
								</TableSection>
							) : null}

							{selectedOrderInfoRef.current.pastSubOrders
								?.length ? (
								<TableSection className="noCheckbox">
									<TableTitle>
										<img src={dhgoc} alt="dhgoc" />
										<span>Đơn hàng đã phát</span>
									</TableTitle>
									<TableDonhangPhanphat
										dsSanpham={
											selectedOrderInfoRef.current
												.pastSubOrders
										}
									/>
								</TableSection>
							) : null}

							{subOrders
								?.filter((sub) =>
									selectedDestinationList.includes(
										sub?.to?._id
									)
								)
								.map((subOrder) => (
									<TableSection
										className="noCheckbox"
										key={subOrder?.to?._id ?? ''}
									>
										<TableTitle>
											<img src={_gsv} alt="gsv" />
											<span>
												Phân phát mới:{' '}
												{subOrder?.to?.ten}
											</span>
										</TableTitle>
										<TableSanphamDonhang
											dsSanpham={subOrder?.products}
											handleChangeProductOrdered={
												handleProductOrderedChanges
											}
											gsvId={subOrder?.to?._id}
										/>
										<div className="text-right">
											<Total>Tổng đơn hàng:</Total>
											<TotalValue>
												{formatMoney(
													getOrderValue(
														subOrder?.products
													)
												)}{' '}
												VNĐ
											</TotalValue>
										</div>

										<RemainingQuantityAlert
											className="mt-3 mb-3"
											products={
												remainingProductQuantities
											}
										/>
									</TableSection>
								))}
						</div>

						<FormContent>
							<SaveButton onClick={handleSubmitSubOrders} />
						</FormContent>
					</Form>
				</Content>
			</Container>

			<DialogMaterial
				open={shouldOpenErrorDialog}
				onClose={closeErrorDialog}
				title="Lỗi số lượng"
				content={errMsg}
				text2="OK"
				onClick2={closeErrorDialog}
			/>

			<ConfirmationDialogMaterial
				shouldOpen={shouldOpenConfirmationDialog}
				onClose={closeConfirmationDialog}
				title="Xác nhận"
				onConfirm={submitSubOrders}
				onCancel={closeConfirmationDialog}
				confirmButtonText="OK"
				cancelButtonText="Hủy"
			>
				<RemainingQuantityAlert
					className="mt-3 mb-1"
					products={remainingProductQuantities}
				/>
			</ConfirmationDialogMaterial>
		</>
	);
};

export default DonhangThem;
