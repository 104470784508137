import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { formatMoney } from '../../../utils';
import { headCellsDonhangGoc } from './headCells';
import styled from 'styled-components';
import img_placeholder from '../../../assets/images/img_placeholder.png';

import { useTablePagination } from '../../../hooks/useTablePagination';
import { tablePaginationOptions } from './tablePaginationOptions';

const TableDonhangGoc = ({ donhang }) => {
	const pagination = useTablePagination(
		donhang?.dssanpham.map((product) => ({
			...product,
			ma: product.sanpham.ma,
			ten: product.sanpham.ten,
			hinhanh: product.sanpham.hinhanh,
			soluong: product.soluong,
			gia: product.sanpham.gia,
		})),
		{
			sortDirection: 'asc',
			sortBy: 'ten',
		}
	);

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={
									pagination.handleSelectAllClick
								}
								onRequestSort={pagination.handleSortRequest}
								rowCount={donhang?.dssanpham.length}
								headCells={headCellsDonhangGoc}
							/>
							<TableBody>
								{pagination.sortedItems.map((row, index) => {
									const isItemSelected =
										pagination.isSelected(row._id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) =>
												pagination.handleRowClick(
													event,
													row._id
												)
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row._id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby':
															labelId,
													}}
												/>
											</TableCell>
											<TableCell align="right">
												{row?.sanpham.ma}
											</TableCell>
											<TableCell align="right">
												<img
													src={
														row?.sanpham.hinhanh
															? `/uploads/${row?.sanpham.hinhanh}`
															: img_placeholder
													}
													alt="anhcongcu"
													style={{
														width: '30px',
													}}
													className={
														!row?.sanpham.hinhanh
															? 'noImage'
															: undefined
													}
												/>
											</TableCell>
											<TableCell align="right">
												{row?.sanpham.ten}
											</TableCell>
											<TableCell align="right">
												<Input
													type="text"
													value={row?.soluong}
													onChange={(e) => {}}
													// onChange={(e) => handleChangeSoluong(e, row._id)}
												/>
											</TableCell>
											<TableCell align="right">
												{formatMoney(
													row?.sanpham.gia *
														row?.soluong
												)}
											</TableCell>
										</TableRow>
									);
								})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={donhang?.dssanpham.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>
		</>
	);
};

const Input = styled.input`
	width: 80px;
	color: #444;
	text-align: center;
	padding: 3px;
	outline: none;
	border-radius: 3px;
	border: 1px solid #ddd;
	&:focus {
		border: 1px solid #999;
	}
`;

export default TableDonhangGoc;
