import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmationDialogMaterial = ({
	onConfirm,
	onCancel,
	title,
	children,
	confirmButtonText,
	cancelButtonText,
	onClose,
	shouldOpen,
}) => {
	return (
		<Dialog
			open={shouldOpen}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent style={{ minWidth: 380 }}>{children}</DialogContent>
			<DialogActions>
				<Button
					onClick={onCancel}
					color="primary"
					autoFocus
					style={{ outline: 'none' }}
				>
					{cancelButtonText}
				</Button>
				<Button
					onClick={onConfirm}
					color="primary"
					style={{ outline: 'none' }}
				>
					{confirmButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationDialogMaterial;
