import React from 'react';
import TableSanpham from './tables/TableSanpham';
import { useSelector } from 'react-redux';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import {
	AddButton,
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import apiGSV from '../../axios/apiGSV';
import { headerDropdownLinks } from './arrayOfLinks';
import { getCurrentDate, thisMonth, thisYear } from '../../utils';
import DialogMaterial from '../../components/DialogMaterial';
import Thongke from '../../components/Thongke';

const Sanpham = (props) => {
	const [query, setQuery] = React.useState('');
	const [searchColumns] = React.useState(['ma', 'ten', 'madonhang']);
	const [loading, setLoading] = React.useState(false);
	const [dsSanpham, setDsSanpham] = React.useState([]);
	const { userInfo } = useSelector((state) => state.user);
	const [open, setOpen] = React.useState(false);
	const [alertMsg, setAlertMsg] = React.useState(false);
	const [dsThongke, setdsThongke] = React.useState([]);
	const [thongkeType, setThongkeType] = React.useState('');
	const [dateRange, setDateRange] = React.useState({
		from: '',
		to: '',
	});

	const handleClickThang = () => {
		setThongkeType('thang');
		const { firstDay, lastDay } = thisMonth();
		let dssp = [];
		for (const sp of dsSanpham) {
			const ngtao = sp.ngaytao;
			if (ngtao >= firstDay && ngtao <= lastDay) {
				dssp = [sp, ...dssp];
			}
		}
		setdsThongke(dssp);
	};

	const handleClickNam = () => {
		setThongkeType('nam');
		const { firstDay, lastDay } = thisYear();
		let dssp = [];
		for (const sp of dsSanpham) {
			const ngtao = sp.ngaytao;
			if (ngtao >= firstDay && ngtao <= lastDay) {
				dssp = [sp, ...dssp];
			}
		}
		setdsThongke(dssp);
	};

	const handleClickThongke = () => {
		if (validateDate()) {
			setThongkeType('range');
			const startDay = new Date(dateRange.from);
			const endDay = new Date(dateRange.to);
			let dssp = [];
			for (const sp of dsSanpham) {
				const ngtao = new Date(sp.ngaytao);
				if (ngtao >= startDay && ngtao <= endDay) {
					dssp = [sp, ...dssp];
				}
			}
			setdsThongke(dssp);
		}
	};

	const validateDate = () => {
		const from = new Date(dateRange.from);
		const to = new Date(dateRange.to);
		const today = new Date(getCurrentDate());
		if (!dateRange.from) {
			setAlertMsg('Vui lòng nhập ngày bắt đầu');
			handleOpen();
			return false;
		}
		if (from > today) {
			setAlertMsg('Ngày bắt đầu không hợp lệ');
			handleOpen();
			return false;
		}
		if (!dateRange.to) {
			setAlertMsg('Vui lòng nhập ngày kết thúc');
			handleOpen();
			return false;
		}
		if (from > to || to < from) {
			setAlertMsg('Ngày bắt đầu vượt quá ngày kết thúc');
			handleOpen();
			return false;
		}
		return true;
	};

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleChangeDateFrom = (e) => {
		setDateRange({ ...dateRange, from: e.target.value });
	};

	const handleChangeDateTo = (e) => {
		setDateRange({ ...dateRange, to: e.target.value });
	};

	const fetchDsSanpham = async () => {
		setLoading(true);
		const { gsv } = await apiGSV.singleGsvBasedUserId(userInfo._id);
		let { dssanpham } = await apiGSV.dsSanpham(gsv._id);
		dssanpham = dssanpham.map((sp) => ({
			...sp.sanpham,
			...sp,
			madonhang: sp.donhang.ma,
		}));
		setDsSanpham(dssanpham);
		setLoading(false);
	};

	const search = (dsSanpham) => {
		return (
			dsSanpham &&
			dsSanpham.filter((item) =>
				searchColumns.some(
					(col) =>
						item[col].toString().toLowerCase().indexOf(query.toLowerCase()) > -1
				)
			)
		);
	};

	React.useEffect(() => {
		fetchDsSanpham();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Sản phẩm"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvung"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách sản phẩm</Title>
							<AddButton
								className="btn btn-primary"
								onClick={() =>
									props.history.push('/giamsatvung/sanpham/giaohang')
								}
							>
								<span>Giao hàng</span>
								<i className="fas fa-plus-circle"></i>
							</AddButton>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tìm sản phẩm theo mã đơn hàng, mã sản phẩm, tên sản phẩm"
									value={query}
									onChange={(e) => setQuery(e.target.value)}
								/>
							</SearchBox>
						</Filter>

						<TableSection className="noCheckbox">
							<TableSanpham dsSanpham={search(dsSanpham)} />
						</TableSection>
					</FilterSection>

					<Thongke
						onClickThang={handleClickThang}
						onClickNam={handleClickNam}
						onClickThongke={handleClickThongke}
						handleChangeDateFrom={handleChangeDateFrom}
						handleChangeDateTo={handleChangeDateTo}
						fromDate={dateRange.from}
						toDate={dateRange.to}
						dsSanpham={dsThongke}
						thongkeType={thongkeType}
					/>
				</Content>
			</Container>

			<DialogMaterial
				open={open}
				onClose={handleClose}
				title="Lỗi"
				content={alertMsg}
				text2="OK"
				onClick2={handleClose}
			/>
		</>
	);
};

export default Sanpham;
