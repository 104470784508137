import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import {
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import TableHodan from './tables/TableHodan';
import BackdropMaterial from '../../components/BackdropMaterial';
import { useSelector } from 'react-redux';
import apiGSV from '../../axios/apiGSV';
import { headerDropdownLinks } from './arrayOfLinks';
import { useSearch } from '../../hooks/useSearch';

const Hodan = (props) => {
	const { queryText, setQueryText, search } = useSearch([
		'daidien',
		'sdt',
		'cmnd',
		'taikhoan',
		'namsinh',
	]);

	const [dsHodan, setDsHodan] = useState([]);
	const [loading, setLoading] = useState(false);
	const [rowsRemoved, setRowsRemoved] = useState(false);
	const { userInfo } = useSelector((state) => state.user);

	const fetchDsHodan = async () => {
		setLoading(true);
		const { gsv } = await apiGSV.singleGsvBasedUserId(userInfo._id);
		let { dshodan } = await apiGSV.dsHodan(gsv._id);
		dshodan = dshodan.map((hd) => ({ ...hd, langnghe: hd.langnghe.ten }));
		setDsHodan(dshodan);
		setLoading(false);
	};

	useEffect(() => {
		setRowsRemoved(false);
		fetchDsHodan();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowsRemoved]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Hộ dân"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvung"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách hộ dân</Title>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tìm hộ dân theo tên đại diện, số điện thoại, cmnd, tài khoản, năm sinh"
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
								/>
							</SearchBox>
						</Filter>
						<TableSection className="noCheckbox">
							<TableHodan
								dsHodan={search(dsHodan)}
								setRowsRemoved={setRowsRemoved}
							/>
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Hodan;
