export const headCellsCongcu = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'hinhanh',
		numeric: true,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Tổng số lượng',
	},
	{
		id: 'mota',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsCongcuHuloi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'hinhanh',
		numeric: true,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'mota',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsDaily2 = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên đại lý',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
	{
		id: 'active',
		numeric: false,
		disablePadding: false,
		label: 'Trạng thái',
	},
];

export const headCellsPhanphat = [
	{
		id: 'bengui',
		numeric: false,
		disablePadding: true,
		label: 'Bên gửi',
	},
	{
		id: 'dailytrunggian',
		numeric: false,
		disablePadding: true,
		label: 'Đại lý trung gian',
	},
	{
		id: 'hodandich',
		numeric: false,
		disablePadding: true,
		label: 'Hộ dân đích',
	},
	{
		id: 'tongcongcu',
		numeric: true,
		disablePadding: false,
		label: 'Tổng số công cụ',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: false,
		label: 'Trạng thái',
	},
	{
		id: 'ngayphanphat',
		numeric: false,
		disablePadding: false,
		label: 'Ngày phân phát',
	},
	{
		id: 'chitiet',
		numeric: false,
		disablePadding: false,
		label: 'Chi tiết',
	},
];

export const headCellsPhanphat2 = [
	{
		id: 'bengui',
		numeric: false,
		disablePadding: true,
		label: 'Bên gửi',
	},
	{
		id: 'dailytrunggian',
		numeric: false,
		disablePadding: true,
		label: 'Đại lý trung gian',
	},
	{
		id: 'hodandich',
		numeric: false,
		disablePadding: true,
		label: 'Hộ dân đích',
	},
	{
		id: 'tongvattu',
		numeric: true,
		disablePadding: false,
		label: 'Tổng vật tư',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: false,
		label: 'Trạng thái',
	},
	{
		id: 'ngayphanphat',
		numeric: false,
		disablePadding: false,
		label: 'Ngày phân phát',
	},
	{
		id: 'chitiet',
		numeric: false,
		disablePadding: false,
		label: 'Chi tiết',
	},
];

export const headCellsPhanphatChitiet = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'tencongcu',
		numeric: false,
		disablePadding: true,
		label: 'Tên công cụ',
	},
	{
		id: 'soluongphanphat',
		numeric: false,
		disablePadding: true,
		label: 'Số lượng phân phát',
	},
	{
		id: 'ngayphanphat',
		numeric: true,
		disablePadding: false,
		label: 'Ngày phân phát',
	},
	{
		id: 'xacnhan',
		numeric: false,
		disablePadding: false,
		label: 'Xác nhận',
	},
];

export const headCellsPhanphatChitiet2 = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'tenvattu',
		numeric: false,
		disablePadding: true,
		label: 'Tên vật tư',
	},
	{
		id: 'soluongphanphat',
		numeric: false,
		disablePadding: true,
		label: 'Số lượng phân phát',
	},
	{
		id: 'ngayphanphat',
		numeric: true,
		disablePadding: false,
		label: 'Ngày phân phát',
	},
	{
		id: 'xacnhan',
		numeric: false,
		disablePadding: false,
		label: 'Xác nhận',
	},
];

export const headCellsHodan = [
	{
		id: 'daidien',
		numeric: false,
		disablePadding: true,
		label: 'Đại diện',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: true,
		label: 'Số điện thoại',
	},
	// {
	// 	id: 'cmnd',
	// 	numeric: false,
	// 	disablePadding: true,
	// 	label: 'CMND',
	// },
	{
		id: 'namsinh',
		numeric: true,
		disablePadding: true,
		label: 'Năm sinh',
	},
	{
		id: 'loaisp',
		numeric: false,
		disablePadding: true,
		label: 'Loại sản phẩm',
	},
	{
		id: 'langnghe',
		numeric: false,
		disablePadding: true,
		label: 'Làng nghề',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: true,
		label: 'Trạng thái',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: true,
		label: '',
	},
];

export const headCellsDonhang = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'tongsanpham',
		numeric: false,
		disablePadding: false,
		label: 'Tổng sản phẩm',
	},
	{
		id: 'tongcongcu',
		numeric: true,
		disablePadding: false,
		label: 'Tổng công cụ',
	},
	{
		id: 'tongvattu',
		numeric: false,
		disablePadding: false,
		label: 'Tổng vật tư',
	},
	{
		id: 'tongnguyenlieu',
		numeric: false,
		disablePadding: false,
		label: 'Tổng nguyên liệu',
	},
	// {
	// 	id: 'tonggia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Tổng giá (VNĐ)',
	// },
	{
		id: 'ngayppden',
		numeric: false,
		disablePadding: false,
		label: 'Phân phát đến',
	},
	{
		id: 'phanphatdi',
		numeric: false,
		disablePadding: false,
		label: 'Phân phát đi',
	},
];

export const headCellsCongcuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
];

export const headCellsNguyenlieuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'khoiluong',
		numeric: false,
		disablePadding: false,
		label: 'Khối lượng',
	},
];

export const headCellsSanphamDonhangChitiet = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'soluonght',
		numeric: false,
		disablePadding: false,
		label: 'Hộ dân hoàn thành',
	},
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'dagiao',
		numeric: false,
		disablePadding: false,
		label: 'Đã giao',
	},
	{
		id: 'conlai',
		numeric: false,
		disablePadding: false,
		label: 'Còn lại',
	},
	// {
	// 	id: 'dongia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Đơn giá (VNĐ)',
	// },
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
];

export const headCellsVattuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
];

export const headCellsDonhangGoc = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: true,
		disablePadding: false,
		label: 'Tên',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	// {
	// 	id: 'dongia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Đơn giá (VNĐ)',
	// },
];

export const headCellsSanphamDonhang = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	// {
	// 	id: 'dongia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Đơn giá (VNĐ)',
	// },
	// {
	// 	id: 'tonggia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Tổng giá (VNĐ)',
	// },
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
];

export const headCellsVattu = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Tổng số lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: true,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsVattuHuloi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: true,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: true,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsNguyenlieu = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'khoiluong',
		numeric: true,
		disablePadding: false,
		label: 'Tổng khối lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	// {
	//   id: "donvitinh",
	//   numeric: false,
	//   disablePadding: false,
	//   label: "Đơn vị tính",
	// },
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsNguyenlieuHuloi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'khoiluong',
		numeric: true,
		disablePadding: false,
		label: 'Khối lượng',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	// {
	//   id: "donvitinh",
	//   numeric: false,
	//   disablePadding: false,
	//   label: "Đơn vị tính",
	// },
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsSanpham = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'masanpham',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Tổng số lượng',
	},
	{
		id: 'soluonghoanthanh',
		numeric: false,
		disablePadding: false,
		label: 'Hộ dân hoàn thành',
	},
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'dagiao',
		numeric: false,
		disablePadding: false,
		label: 'Đã giao',
	},
	{
		id: 'conlai',
		numeric: false,
		disablePadding: false,
		label: 'Còn lại',
	},
	{
		id: 'qrcode',
		numeric: false,
		disablePadding: false,
		label: 'QR Code',
	},
	// {
	// 	id: 'tonggia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Tổng giá (VNĐ)',
	// },
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
	// {
	//   id: "ngaytao",
	//   numeric: false,
	//   disablePadding: false,
	//   label: "Ngày tạo",
	// },
];

export const headCellsGiaohang = [
	{
		id: 'masanpham',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	// {
	//   id: "hinhanh",
	//   numeric: false,
	//   disablePadding: true,
	//   label: "Hình ảnh",
	// },
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng thực hiện',
	},
	{
		id: 'hodanhoanthanh',
		numeric: false,
		disablePadding: false,
		label: 'Hộ dân hoàn thành',
	},
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'dagiaodi',
		numeric: false,
		disablePadding: false,
		label: 'Đã giao đi',
	},
	{
		id: 'conlai',
		numeric: false,
		disablePadding: false,
		label: 'Còn lại',
	},
	{
		id: 'soluonggiao',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng giao',
	},
];

export const headCellsGiaohangDen = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'daily2',
		numeric: false,
		disablePadding: true,
		label: 'Đại lý 2',
	},
	{
		id: 'tongsanpham',
		numeric: false,
		disablePadding: false,
		label: 'Tổng sản phẩm',
	},
	{
		id: 'ngaygiao',
		numeric: false,
		disablePadding: true,
		label: 'Ngày giao đến',
	},
	{
		id: 'xacnhan',
		numeric: false,
		disablePadding: true,
		label: 'Ngày xác nhận',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: true,
		label: '',
	},
];

export const headCellsGiaohangDi = [
	{
		id: 'madonhang',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'gsv',
		numeric: false,
		disablePadding: true,
		label: 'Giám sát vùng',
	},
	{
		id: 'tongsanpham',
		numeric: false,
		disablePadding: false,
		label: 'Tổng sản phẩm',
	},
	{
		id: 'ngaygiao',
		numeric: false,
		disablePadding: true,
		label: 'Ngày giao đi',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: true,
		label: 'Trạng thái',
	},
	{
		id: 'ngayxacnhan',
		numeric: false,
		disablePadding: true,
		label: 'Ngày xác nhận',
	},
];

export const headCellsHanggiaodenChitiet = [
	{
		id: 'masp',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'tensp',
		numeric: false,
		disablePadding: true,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'slthuchien',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng thực hiện',
	},
	// {
	// 	id: 'slhoanthanh',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Hộ dân hoàn thành',
	// },
	{
		id: 'sldanhan',
		numeric: false,
		disablePadding: false,
		label: 'Đại lý 2 đã nhận',
	},
	{
		id: 'soluonggiao',
		numeric: false,
		disablePadding: false,
		label: 'Số luợng giao',
	},
];

export const headCellsHanggiaodiChitiet = [
	{
		id: 'masp',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'tensp',
		numeric: false,
		disablePadding: true,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'slthuchien',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng thực hiện',
	},
	// {
	// 	id: 'slhoanthanh',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Hộ dân hoàn thành',
	// },
	{
		id: 'danhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'soluonggiao',
		numeric: false,
		disablePadding: false,
		label: 'Số luợng giao',
	},
];
