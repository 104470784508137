import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import apiGSV from '../../axios/apiGSV';
import apiGiaohang from '../../axios/apiGiaohang';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import {
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import TableHanggiaodi from './tables/TableHanggiaodi';
import { headerDropdownLinks } from './arrayOfLinks';

import { useSearch } from '../../hooks/useSearch';
import { useDsGiaohang } from '../shared/giaohang/useDsGiaohang';

const Hanggiaodi = () => {
	const { userInfo } = useSelector((state) => state.user);
	const { queryText, setQueryText, search } = useSearch(['madonhang']);

	const fetchDsGiaohang = useCallback(async () => {
		const { gsv } = await apiGSV.singleGsvBasedUserId(userInfo._id);
		let { dsgiaohang } = await apiGiaohang.dsGiaohangDiGsvBophankd(gsv._id);
		return dsgiaohang;
	}, [userInfo]);

	const { isLoading, dsGiaohang } = useDsGiaohang(fetchDsGiaohang);

	if (isLoading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Hàng giao đi"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvung"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách hàng giao đi</Title>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tim hàng theo mã đơn hàng"
									value={queryText}
									onChange={(e) =>
										setQueryText(e.target.value)
									}
								/>
							</SearchBox>
						</Filter>
						<TableSection className="noCheckbox">
							<TableHanggiaodi dsGiaohang={search(dsGiaohang)} />
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Hanggiaodi;
