import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import apiGiaohang from '../../axios/apiGiaohang';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import {
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import TableHanggiaoden from './tables/TableHanggiaoden';
import apiBophankd from '../../axios/apiBophankd';
import { headerDropdownLinks } from './arrayOfLinks';

import { useSearch } from '../../hooks/useSearch';
import { useDsGiaohang } from '../shared/giaohang/useDsGiaohang';

const Hanggiaoden = () => {
	const { userInfo } = useSelector((state) => state.user);
	const { queryText, setQueryText, search } = useSearch(['madonhang']);

	const fetchDsGiaohang = useCallback(async () => {
		const { bophankd } = await apiBophankd.bophankdReadOnlyUserId(userInfo._id);
		let { dsgiaohang } = await apiGiaohang.dsGiaohangDenGsvBophankd(
			bophankd._id
		);
		return dsgiaohang;
	}, [userInfo]);

	const { isLoading, dsGiaohang } = useDsGiaohang(fetchDsGiaohang);

	if (isLoading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Hàng giao đến"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvien"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách hàng giao đến</Title>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tim hàng theo mã đơn hàng"
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
								/>
							</SearchBox>
						</Filter>
						<TableSection className="noCheckbox">
							<TableHanggiaoden dsGiaohang={search(dsGiaohang)} />
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Hanggiaoden;
