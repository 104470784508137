import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from 'react-router-dom';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { formatMoney } from '../../../utils';
import { headCellsDonhang } from './headCells';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import DialogMaterial from '../../../components/DialogMaterial';
import TableButton from '../../../components/TableButton';
import { toast } from 'react-toastify';
import apiDonhang from '../../../axios/apiDonhang';
import { Link } from 'react-router-dom';
import { StateContext } from '../../../Context/StateContext';
import { useContext } from 'react';
import { useConfirm } from 'material-ui-confirm';

import { useTablePagination } from '../../../hooks/useTablePagination';
import { tablePaginationOptions } from './tablePaginationOptions';
import { formatddMMyyyy } from '../../../utils';

const EnhancedTableToolbar = ({
	numSelected,
	rowsSelected,
	onClickChitiet,
	onClickCapnhat,
	onClickXoa,
}) => {
	return numSelected > 0 ? (
		<>
			<Toolbar
				sx={{
					pl: { sm: 7 },
					pr: { xs: 1, sm: 1 },
					...(numSelected > 0 && {
						bgcolor: (theme) =>
							alpha(
								theme.palette.primary.main,
								theme.palette.action.activatedOpacity
							),
					}),
				}}
			>
				{numSelected > 0 ? (
					<Typography
						sx={{ flex: '1 1 100%' }}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						<div className="d-flex align-items-center">
							{rowsSelected.length === 1 ? (
								<>
									<TableButton onClick={onClickChitiet}>Chi tiết</TableButton>
									<TableButton onClick={onClickXoa}>Xóa</TableButton>
									{/* <TableButton onClick={onClickCapnhat}>
										Hủy đơn hàng
									</TableButton> */}
								</>
							) : (
								<>
									<TableButton onClick={onClickXoa}>Xóa</TableButton>
									{/* <TableButton onClick={onClickCapnhat}>
										Hủy đơn hàng
									</TableButton> */}
								</>
							)}
						</div>
					</Typography>
				) : (
					<Typography
						sx={{ flex: '1 1 100%' }}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						Nutrition
					</Typography>
				)}
			</Toolbar>
		</>
	) : null;
};

const TableDonhang = ({ dsDonhang = [], onTableUpdated }) => {
	const pagination = useTablePagination(dsDonhang, {
		sortDirection: 'desc',
		sortBy: 'ngaytao',
	});
	const [shouldShowDialog, showDialog] = React.useState(false);
	const history = useHistory();
	const context = useContext(StateContext);
	const confirm = useConfirm();

	const handleCancelOrder = (code) => {
		confirm({
			title: 'Hủy đơn hàng?',
			description: `Bạn có chắc muốn hủy đơn hàng ${code} không?`,
			cancellationText: 'Không',
			confirmationText: 'Có',
		}).then(() => {
			context.handleCancelOrder(code);
			onTableUpdated(true);
		});
	};

	const onClickChitiet = () =>
		history.push(`/admin/donhang/chitiet/${pagination.selectedItems[0]}`);

	const onClickCapnhat = () => handleCancelOrder(context.code);

	const onClickXoa = () => showDialog(true);

	const handleDeleteRows = async () => {
		const { success } = await apiDonhang.xoaNhieuDonhang({
			arrOfIds: pagination.selectedItems,
		});
		if (success) {
			toast.success('Xóa thành công!', { theme: 'colored' });
			onTableUpdated(true);
		}
	};

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<EnhancedTableToolbar
						numSelected={pagination.selectedItems.length}
						rowsSelected={pagination.selectedItems}
						onClickChitiet={onClickChitiet}
						onClickCapnhat={onClickCapnhat}
						onClickXoa={onClickXoa}
					/>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={pagination.handleSelectAllClick}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsDonhang.length}
								headCells={headCellsDonhang}
							/>
							<TableBody>
								{pagination.sortedItems
									.filter((row) => !row.bihuy)
									.map((row, index) => {
										const isItemSelected = pagination.isSelected(row._id);
										const labelId = `enhanced-table-checkbox-${index}`;
										return (
											<TableRow
												hover
												onClick={(event) => {
													pagination.handleRowClick(event, row._id);
													context.setCode(row.ma);
												}}
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row._id}
												selected={isItemSelected}
											>
												<TableCell padding="checkbox">
													<Checkbox
														color="primary"
														checked={isItemSelected}
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
												<TableCell align="right">
													<Link to={`/admin/donhang/chitiet/${row._id}`}>
														{row.ma}
													</Link>
												</TableCell>
												<TableCell align="right">{row.tongsanpham}</TableCell>
												<TableCell align="right">{row.tongcongcu}</TableCell>
												<TableCell align="right">{row.tongvattu}</TableCell>
												<TableCell align="right">
													{row.tongnguyenlieu} kg
												</TableCell>
												<TableCell align="right">
													{formatMoney(row.tongdongia)}
												</TableCell>
												<TableCell align="right">
													{formatddMMyyyy(row.ngaytao)}
												</TableCell>
											</TableRow>
										);
									})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={dsDonhang?.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>

			<DialogMaterial
				open={shouldShowDialog}
				onClose={() => showDialog(false)}
				title="Xóa đơn hàng"
				content="Bạn chắc xóa đơn hàng này chứ?"
				text1="Hủy"
				text2="Xóa"
				onClick1={() => showDialog(false)}
				onClick2={handleDeleteRows}
			/>
		</>
	);
};

export default TableDonhang;
