import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from 'react-router-dom';
import img_placeholder from '../../../assets/images/img_placeholder.png';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { headCellsCongcu } from './headCells';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DialogMaterial from '../../../components/DialogMaterial';
import TableButton from '../../../components/TableButton';
import { toast } from 'react-toastify';
import apiCongcu from '../../../axios/apiCongcu';
import { Link } from 'react-router-dom';

import { useTablePagination } from '../../../hooks/useTablePagination';
import { tablePaginationOptions } from './tablePaginationOptions';
import { formatddMMyyyy } from '../../../utils';

const EnhancedTableToolbar = ({
	numSelected,
	rowsSelected,
	onClickChitiet,
	onClickCapnhat,
	onClickXoa,
}) => {
	return numSelected > 0 ? (
		<>
			<Toolbar
				sx={{
					pl: { sm: 7 },
					pr: { xs: 1, sm: 1 },
					...(numSelected > 0 && {
						bgcolor: (theme) =>
							alpha(
								theme.palette.primary.main,
								theme.palette.action.activatedOpacity
							),
					}),
				}}
			>
				{numSelected > 0 ? (
					<Typography
						sx={{ flex: '1 1 100%' }}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						<div className="d-flex align-items-center">
							{rowsSelected.length === 1 ? (
								<>
									<TableButton onClick={onClickChitiet}>
										Chi tiết
									</TableButton>
									<TableButton onClick={onClickCapnhat}>
										Cập nhật
									</TableButton>
									<TableButton onClick={onClickXoa}>
										Xóa
									</TableButton>
								</>
							) : (
								<TableButton onClick={onClickXoa}>
									Xóa
								</TableButton>
							)}
						</div>
					</Typography>
				) : (
					<Typography
						sx={{ flex: '1 1 100%' }}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						Nutrition
					</Typography>
				)}
			</Toolbar>
		</>
	) : null;
};

const TableCongcu = ({ dsCongcu = [], setRowsRemoved, bophankdId }) => {
	const pagination = useTablePagination(dsCongcu, {
		sortDirection: 'asc',
		sortBy: 'ten',
	});

	const [open, setOpen] = React.useState(false);
	const history = useHistory();

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const onClickChitiet = () =>
		history.push(`/admin/congcu/chitiet/${pagination.selectedItems[0]}`);

	const onClickCapnhat = () =>
		history.push(`/admin/congcu/chinhsua/${pagination.selectedItems[0]}`);

	const onClickXoa = () => handleOpen();

	const handleDeleteRow = async () => {
		const { success } = await apiCongcu.xoaNhieuCongcu({
			arrOfIds: pagination.selectedItems,
		});
		if (success) {
			toast.success('Xóa thành công!', { theme: 'colored' });
			setRowsRemoved(true);
		}
	};

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<EnhancedTableToolbar
						numSelected={pagination.selectedItems.length}
						rowsSelected={pagination.selectedItems}
						onClickChitiet={onClickChitiet}
						onClickCapnhat={onClickCapnhat}
						onClickXoa={onClickXoa}
					/>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={
									pagination.handleSelectAllClick
								}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsCongcu?.length}
								headCells={headCellsCongcu}
							/>
							<TableBody>
								{pagination.sortedItems.map((row, index) => {
									const isItemSelected =
										pagination.isSelected(row._id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) =>
												pagination.handleRowClick(
													event,
													row._id
												)
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row._id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby':
															labelId,
													}}
												/>
											</TableCell>
											<TableCell>
												<img
													src={
														row.hinhanh
															? `/uploads/${row.hinhanh}`
															: img_placeholder
													}
													alt="anhcongcu"
													style={{
														width: '30px',
													}}
													className={
														!row.hinhanh
															? 'noImage'
															: undefined
													}
												/>
											</TableCell>
											<TableCell align="right">
												<Link
													to={`/admin/congcu/chitiet/${row._id}`}
												>
													{row.ten}
												</Link>
											</TableCell>
											<TableCell align="right">
												{row.congdung}
											</TableCell>
											<TableCell align="right">
												{formatddMMyyyy(row.ngaytao)}
											</TableCell>
										</TableRow>
									);
								})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={dsCongcu?.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>

			<DialogMaterial
				open={open}
				onClose={handleClose}
				title="Xóa công cụ"
				content="Bạn chắc xóa những công cụ này chứ?"
				text1="Hủy"
				text2="Xóa"
				onClick1={handleClose}
				onClick2={handleDeleteRow}
			/>
		</>
	);
};

export default TableCongcu;
