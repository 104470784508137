export const headCellsDsBophankd = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
];

export const headCellsDaily1 = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
];

export const headCellsDaily2 = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
];

export const headCellsHodan = [
	{
		id: 'daidien',
		numeric: false,
		disablePadding: true,
		label: 'Đại diện',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'cmnd',
		numeric: false,
		disablePadding: false,
		label: 'CMND',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
	{
		id: 'namsinh',
		numeric: true,
		disablePadding: false,
		label: 'Năm sinh',
	},
];

export const headCellsGsv = [
	{
		id: 'ten',
		numeric: false,
		disablePadding: true,
		label: 'Tên',
	},
	{
		id: 'sdt',
		numeric: false,
		disablePadding: false,
		label: 'Số điện thoại',
	},
	{
		id: 'cmnd',
		numeric: false,
		disablePadding: false,
		label: 'CMND',
	},
	{
		id: 'taikhoan',
		numeric: false,
		disablePadding: false,
		label: 'Tài khoản',
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: 'E-mail',
	},
];

export const headCellsSanpham = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'loai',
		numeric: false,
		disablePadding: false,
		label: 'Loại',
	},
	{
		id: 'gia',
		numeric: false,
		disablePadding: false,
		label: 'Giá (VNĐ)',
	},
	{
		id: 'qrcode',
		numeric: false,
		disablePadding: false,
		label: 'QR Code',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày khởi tạo',
	},
];

export const headCellsLoaiSanpham = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã loại',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên loại',
	},
	{
		id: 'mota',
		numeric: false,
		disablePadding: false,
		label: 'Mô tả',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsNhanhieu = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nhãn hiệu',
	},
	{
		id: 'mota',
		numeric: false,
		disablePadding: false,
		label: 'Mô tả',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsCongcu = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsVattu = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];

export const headCellsNguyenlieu = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày khởi tạo',
	},
];

export const headCellsSanphamDonhang = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'loai',
		numeric: false,
		disablePadding: false,
		label: 'Loại',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	{
		id: 'gia',
		numeric: false,
		disablePadding: false,
		label: 'Giá (VNĐ)',
	},
];

export const headCellsCongcuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên công cụ',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
];

export const headCellsVattuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên vật tư',
	},
	{
		id: 'congdung',
		numeric: false,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
];

export const headCellsNguyenlieuDonhang = [
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên nguyên liệu',
	},
	{
		id: 'congdung',
		numeric: true,
		disablePadding: false,
		label: 'Công dụng',
	},
	{
		id: 'khoiluong',
		numeric: false,
		disablePadding: false,
		label: 'Khối lượng',
	},
];

export const headCellsDonhang = [
	{
		id: 'ma',
		numeric: false,
		disablePadding: true,
		label: 'Mã đơn hàng',
	},
	{
		id: 'tongsanpham',
		numeric: false,
		disablePadding: false,
		label: 'Tổng sản phẩm',
	},
	{
		id: 'tongcongcu',
		numeric: true,
		disablePadding: false,
		label: 'Tổng công cụ',
	},
	{
		id: 'tongvattu',
		numeric: false,
		disablePadding: false,
		label: 'Tổng vật tư',
	},
	{
		id: 'tongnguyenlieu',
		numeric: false,
		disablePadding: false,
		label: 'Tổng nguyên liệu',
	},
	{
		id: 'tongdongia',
		numeric: false,
		disablePadding: false,
		label: 'Tổng đơn giá (VNĐ)',
	},
	{
		id: 'ngaytao',
		numeric: false,
		disablePadding: false,
		label: 'Ngày tạo',
	},
];
