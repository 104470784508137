import React, { useState } from 'react';
import {
	Container,
	Content,
	ErrMsg,
	Form,
	FormContent,
	FormGroup,
	FormTitle,
	Input,
	Label,
} from './styledComponents';
import Header from '../../components/Header';
import { apiTinhThanh } from '../../apiTinhThanh';
import apiDaily1 from '../../axios/apiDaily1';
import { useSelector } from 'react-redux';
import apiGSV from '../../axios/apiGSV';
import BackdropMaterial from '../../components/BackdropMaterial';
import { toast } from 'react-toastify';
import DropdownMaterial2 from '../../components/DropdownMaterial2';
import MenuItem from '@mui/material/MenuItem';
import ten from '../../assets/icons/ten.png';
import sdt from '../../assets/icons/sdt.png';
import email from '../../assets/icons/email.png';
import diachi from '../../assets/icons/diachi.png';
import taikhoan from '../../assets/icons/taikhoan.png';
import SaveButton from '../../components/SaveButton';

const Daily1Them = (props) => {
	const [loading, setLoading] = React.useState(false);
	const [gsvInfo, setGsvInfo] = React.useState(false);
	const [daily1, setDaily1] = useState({
		ten: '',
		taikhoan: '',
		sdt: '',
		email: '',
	});
	const [errMsg, setErrMsg] = useState('');
	const [tinh, setTinh] = useState(null);
	const [huyen, sethuyen] = useState(null);
	const [xa, setXa] = useState(null);
	const { userInfo } = useSelector((state) => state.user);
	const [dsTaikhoan, setDsTaikhoan] = useState([]);
	const [taikhoanErr, setTaikhoanErr] = useState('');
	const [success, setSuccess] = useState('');

	const handleChangeTaikhoan = (e) => {
		var format = /[!@#$%^&*()+=[\]{};':"\\|,.<>/?]+/;
		const val = e.target.value.toLowerCase();
		setDaily1({ ...daily1, taikhoan: val });
		// check white space
		if (val.indexOf(' ') >= 0) {
			setTaikhoanErr('Tài khoản không có khoảng trắng');
		} else if (dsTaikhoan.includes(val)) {
			// check maSP exist
			setTaikhoanErr('Tài khoản đã tồn tại');
		} else if (format.test(val)) {
			// check contains special chars
			setTaikhoanErr('Tài khoản không được chứa kí tự đặc biệt');
		} else {
			setTaikhoanErr('');
		}
	};

	const dsTinh = apiTinhThanh.map((item) => item.name);
	const dsHuyen = apiTinhThanh
		.find((item) => item.name === tinh)
		?.districts.map((item) => item.name);
	const dsXa = apiTinhThanh
		.find((item) => item.name === tinh)
		?.districts.find((item) => item.name === huyen)
		?.wards.map((item) => item.name);

	const handleChangeDaily1 = (e) => {
		setDaily1({
			...daily1,
			[e.target.name]: e.target.value,
		});
	};

	const validateFields = () => {
		if (taikhoanErr) {
			return false;
		}
		if (!daily1.taikhoan) {
			setTaikhoanErr('Thông tin không được để trống');
			return false;
		}
		if (daily1.taikhoan.length < 6) {
			setTaikhoanErr('Tài khoản có ít nhất 6 kí tự');
			return false;
		}
		if (
			!daily1.ten ||
			!tinh ||
			!huyen ||
			!xa ||
			!daily1.sdt ||
			!daily1.email
		) {
			setErrMsg('Thông tin không được để trống');
			return false;
		}
		return true;
	};

	const handleSubmit = async () => {
		if (validateFields()) {
			const dl = {
				ten: daily1.ten,
				sdt: daily1.sdt,
				email: daily1.email,
				xa,
				huyen,
				tinh,
				taikhoan: daily1.taikhoan,
				bophankdId: gsvInfo.bophankd,
				gsvId: gsvInfo._id,
			};
			const { success } = await apiDaily1.themDaily1(dl);
			if (success) {
				toast.success('Thêm thành công!', { theme: 'colored' });
				resetFields();
				setSuccess(true);
			}
		}
	};

	const resetFields = () => {
		setDaily1({
			ten: '',
			taikhoan: '',
			sdt: '',
			email: '',
		});
		setErrMsg('');
		setTinh(null);
		sethuyen(null);
		setXa(null);
		setErrMsg('');
	};

	const fetchGsvInfo = async () => {
		setLoading(true);
		const { gsv } = await apiGSV.singleGsvBasedUserId(userInfo._id);
		const { daily1 } = await apiDaily1.dsDaily1();
		setDsTaikhoan(daily1 ? daily1.map((dl1) => dl1.taikhoan) : []);
		setGsvInfo(gsv);
		setLoading(false);
	};

	React.useEffect(() => {
		setSuccess(false);
		fetchGsvInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [success]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Quay lại danh sách đại lý 1"
					titleBack
					onClick={() => props.history.push('/giamsatvung/daily1')}
					headerRight={<SaveButton onClick={handleSubmit} />}
				/>
				<Content>
					<Form>
						<FormContent>
							<FormTitle>
								<span>Thêm đại lý 1</span>
							</FormTitle>

							<FormGroup>
								<Label>
									<img src={ten} alt="ten" />
									<span>Tên đại lý:</span>
								</Label>
								<Input
									placeholder="Nhập tên đại lý"
									type="text"
									name="ten"
									value={daily1.ten}
									onChange={handleChangeDaily1}
								/>
								{!daily1.ten && <ErrMsg>{errMsg}</ErrMsg>}
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={sdt} alt="sdt" />
									<span>Số điện thoại:</span>
								</Label>
								<Input
									placeholder="Nhập số điện thoại"
									type="text"
									name="sdt"
									value={daily1.sdt}
									onChange={handleChangeDaily1}
								/>
								{!daily1.sdt && <ErrMsg>{errMsg}</ErrMsg>}
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={email} alt="email" />
									<span>E-mail:</span>
								</Label>
								<Input
									placeholder="Nhập email"
									type="text"
									name="email"
									value={daily1.email}
									onChange={handleChangeDaily1}
								/>
								{!daily1.email && <ErrMsg>{errMsg}</ErrMsg>}
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={diachi} alt="diachi" />
									<span>Địa chỉ:</span>
								</Label>
								<div className="row">
									<div className="col-lg-4">
										{dsTinh && dsTinh.length ? (
											<DropdownMaterial2
												label="Chọn Tỉnh/Thành Phố"
												value={tinh ?? ''}
												onChange={(e) => {
													setTinh(e.target.value);
													sethuyen(null);
													setXa(null);
												}}
											>
												{dsTinh.map((item) => (
													<MenuItem
														value={item}
														key={item.codename}
													>
														{item}
													</MenuItem>
												))}
											</DropdownMaterial2>
										) : (
											<DropdownMaterial2 label="Chọn Tỉnh/Thành Phố" />
										)}
										{!tinh && <ErrMsg>{errMsg}</ErrMsg>}
									</div>

									<div className="col-lg-4">
										{dsHuyen && dsHuyen.length ? (
											<DropdownMaterial2
												label="Chọn Quận/Huyện"
												value={huyen ?? ''}
												onChange={(e) => {
													sethuyen(e.target.value);
													setXa(null);
												}}
											>
												{dsHuyen.map((item) => (
													<MenuItem
														value={item}
														key={item.codename}
													>
														{item}
													</MenuItem>
												))}
											</DropdownMaterial2>
										) : (
											<DropdownMaterial2 label="Chọn Quận/Huyện" />
										)}
										{!huyen && <ErrMsg>{errMsg}</ErrMsg>}
									</div>

									<div className="col-lg-4">
										{dsXa && dsXa.length ? (
											<DropdownMaterial2
												label="Chọn Phường/Xã"
												value={xa ?? ''}
												onChange={(e) => {
													setXa(e.target.value);
												}}
											>
												{dsXa.map((item) => (
													<MenuItem
														value={item}
														key={item.codename}
													>
														{item}
													</MenuItem>
												))}
											</DropdownMaterial2>
										) : (
											<DropdownMaterial2 label="Chọn Phường/Xã" />
										)}
										{!xa && <ErrMsg>{errMsg}</ErrMsg>}
									</div>
								</div>
							</FormGroup>

							<FormGroup>
								<Label>
									<img src={taikhoan} alt="taikhoan" />
									<span>Tên tài khoản:</span>
								</Label>
								<Input
									placeholder="Nhập tài khoản"
									type="text"
									name="taikhoan"
									value={daily1.taikhoan}
									onChange={handleChangeTaikhoan}
								/>
								{<ErrMsg>{taikhoanErr}</ErrMsg>}
							</FormGroup>

							<SaveButton onClick={handleSubmit} />
						</FormContent>
					</Form>
				</Content>
			</Container>
		</>
	);
};

export default Daily1Them;
