import React, { useState, useEffect, useCallback, useRef } from 'react';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import {
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import TableDonhang from './tables/TableDonhang';
import { useSelector } from 'react-redux';
import apiGSV from '../../axios/apiGSV';
import { headerDropdownLinks } from './arrayOfLinks';

import { useSearch } from '../../hooks/useSearch';

const Donhang = (props) => {
	const { queryText, setQueryText, search } = useSearch(['ma']);

	const [loading, setLoading] = useState(false);
	const [dsDonhang, setDsDonhang] = useState([]);
	const { userInfo } = useSelector((state) => state.user);
	const mountedRef = useRef(true);

	const fetchDsSanpham = useCallback(async () => {
		setLoading(true);
		const { gsv } = await apiGSV.singleGsvBasedUserId(userInfo._id);
		const { donhang } = await apiGSV.dsDonhang(gsv._id);

		if (!mountedRef.current) return;

		setDsDonhang(donhang);
		setLoading(false);
	}, [userInfo._id]);

	useEffect(() => {
		fetchDsSanpham();

		return () => {
			mountedRef.current = false;
		};
	}, [fetchDsSanpham]);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Đơn hàng"
					arrOfLinks={headerDropdownLinks}
					vaitro="giamsatvung"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách đơn hàng</Title>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tìm đơn hàng theo mã"
									value={queryText}
									onChange={(e) =>
										setQueryText(e.target.value)
									}
								/>
							</SearchBox>
						</Filter>

						<TableSection>
							<TableDonhang dsDonhang={search(dsDonhang)} />
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Donhang;
