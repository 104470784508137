import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import TableBophankd from './tables/TableBophankd';
import apiBophankd from '../../axios/apiBophankd';
import BackdropMaterial from '../../components/BackdropMaterial';
import {
	AddButton,
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import { headerDropdownLinks } from './arrayOfLinks';
import { useSearch } from '../../hooks/useSearch';

const Bophankd = (props) => {
	const { queryText, setQueryText, search } = useSearch([
		'ten',
		'sdt',
		'email',
		'taikhoan',
	]);
	const [dsBophankd, setDsBophankd] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchDsBophankd = async () => {
		setLoading(true);
		const { bophankd } = await apiBophankd.dsBophankd();

		setDsBophankd(
			bophankd && bophankd.length
				? bophankd.map((item) => ({
						...item,
						taikhoan: item.user ? item.user.taikhoan : '',
				  }))
				: []
		);
		setLoading(false);
	};

	useEffect(() => {
		fetchDsBophankd();
	}, []);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Bộ phận kinh doanh"
					arrOfLinks={headerDropdownLinks}
					vaitro="admin"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách bộ phận kinh doanh</Title>
							<AddButton
								className="btn btn-primary"
								onClick={() =>
									props.history.push('/admin/bophankd/them')
								}
							>
								<span>Thêm</span>
								<i className="fas fa-plus-circle"></i>
							</AddButton>
						</TitleWrapper>

						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tim bộ phận kinh doanh theo tên, số điện thoại, email, tài khoản"
									value={queryText}
									onChange={(e) =>
										setQueryText(e.target.value)
									}
								/>
							</SearchBox>
						</Filter>
						<TableSection>
							<TableBophankd
								dsBophankd={search(dsBophankd)}
								onTableUpdated={() => {
									fetchDsBophankd();
								}}
							/>
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default Bophankd;
