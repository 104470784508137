export const headCellsHanggiaodenChitiet = [
	{
		id: 'masp',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'tensp',
		numeric: false,
		disablePadding: true,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'slthuchien',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng thực hiện',
	},
	{
		id: 'slgiao',
		numeric: false,
		disablePadding: false,
		label: 'Số luợng giao',
	},
	{
		id: 'slnhan',
		numeric: false,
		disablePadding: false,
		label: 'Đã nhận',
	},
	{
		id: 'slloi',
		numeric: false,
		disablePadding: false,
		label: 'Số luợng lỗi',
	},
];

export const headCellsHanggiaodiChitiet = [
	{
		id: 'masp',
		numeric: false,
		disablePadding: true,
		label: 'Mã sản phẩm',
	},
	{
		id: 'tensp',
		numeric: false,
		disablePadding: true,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: false,
		label: 'Hình ảnh',
	},
	{
		id: 'slthuchien',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng thực hiện',
	},
	{
		id: 'sldagiao',
		numeric: false,
		disablePadding: false,
		label: 'Đã giao',
	},
	{
		id: 'sldanhan',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng nhận',
	},
	{
		id: 'slloi',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng lỗi',
	},
];

export const headCellsDonhangPhanphat = [
	{
		id: 'noinhan',
		numeric: false,
		disablePadding: false,
		label: 'Nơi nhận',
	},
	{
		id: 'ten',
		numeric: false,
		disablePadding: false,
		label: 'Tên sản phẩm',
	},
	{
		id: 'hinhanh',
		numeric: false,
		disablePadding: true,
		label: 'Hình ảnh',
	},
	{
		id: 'soluong',
		numeric: false,
		disablePadding: false,
		label: 'Số lượng',
	},
	// {
	// 	id: 'dongia',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Đơn giá (VNĐ)',
	// },
	{
		id: 'thoihan',
		numeric: false,
		disablePadding: false,
		label: 'Thời hạn',
	},
	{
		id: 'trangthai',
		numeric: false,
		disablePadding: false,
		label: 'Trạng thái',
	},
];
