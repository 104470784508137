import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

export let tablePaginationOptions = {
	rowsPerPageOptions: [20, 30, 40, { label: 'All', value: -1 }],
	colSpan: 3,
	SelectProps: {
		inputProps: {
			'aria-label': 'số dòng trên trang',
		},
		native: true,
	},
	ActionsComponent: TablePaginationActions,
	component: 'div',
};
