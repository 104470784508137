import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Link, useHistory } from 'react-router-dom';
import EnhancedTableHead from '../../../components/table/EnhancedTableHead';
import { formatMoney } from '../../../utils';
import { headCellsDonhang } from './headCells';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import TableButton from '../../../components/TableButton';
// import styled from 'styled-components';

import { useTablePagination } from '../../../hooks/useTablePagination';
import { tablePaginationOptions } from './tablePaginationOptions';
import { exportOrder } from '../../shared/utils/exportToExcel';
import { formatddMMyyyy } from '../../../utils';

const EnhancedTableToolbar = ({
	numSelected,
	rowsSelected,
	onClickChitiet,
	onClickTiendo,
	onClickXuatExcel,
}) => {
	return numSelected > 0 ? (
		<>
			<Toolbar
				sx={{
					pl: { sm: 7 },
					pr: { xs: 1, sm: 1 },
					...(numSelected > 0 && {
						bgcolor: (theme) =>
							alpha(
								theme.palette.primary.main,
								theme.palette.action.activatedOpacity
							),
					}),
				}}
			>
				{numSelected > 0 ? (
					<Typography
						sx={{ flex: '1 1 100%' }}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						<div className="d-flex align-items-center">
							{rowsSelected.length === 1 && (
								<>
									<TableButton onClick={onClickChitiet}>Chi tiết</TableButton>
									<TableButton onClick={onClickTiendo}>Tiến độ</TableButton>
									<TableButton onClick={onClickXuatExcel}>
										Xuất Excel
									</TableButton>
								</>
							)}
						</div>
					</Typography>
				) : (
					<Typography
						sx={{ flex: '1 1 100%' }}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						Nutrition
					</Typography>
				)}
			</Toolbar>
		</>
	) : null;
};

const TableDonhang = ({ dsDonhang = [], setRowsRemoved }) => {
	const pagination = useTablePagination(dsDonhang, {
		sortDirection: 'desc',
		sortBy: 'ngaydathang',
	});

	const history = useHistory();

	const onClickChitiet = () =>
		history.push(`/bophankd/donhang/chitiet/${pagination.selectedItems[0]}`);

	const onClickTiendo = () =>
		history.push(
			`/bophankd/donhang/chitiet/${pagination.selectedItems[0]}/tiendo`
		);

	const onClickXuatExcel = () => {
		const order = dsDonhang.find(
			(item) => item._id === pagination.selectedItems[0]
		);
		exportOrder(order, `BPKD-${order.ma}.xlsx`, true);
	};

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2 }}>
					<EnhancedTableToolbar
						numSelected={pagination.selectedItems.length}
						rowsSelected={pagination.selectedItems}
						onClickChitiet={onClickChitiet}
						onClickTiendo={onClickTiendo}
						onClickXuatExcel={onClickXuatExcel}
					/>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
							id="tableMaterial"
						>
							<EnhancedTableHead
								numSelected={pagination.selectedItems.length}
								order={pagination.sortDirection}
								orderBy={pagination.sortBy}
								onSelectAllClick={pagination.handleSelectAllClick}
								onRequestSort={pagination.handleSortRequest}
								rowCount={dsDonhang.length}
								headCells={headCellsDonhang}
							/>
							<TableBody>
								{pagination.sortedItems
									.filter((row) => !row.bihuy)
									.map((row, index) => {
										const isItemSelected = pagination.isSelected(row._id);
										const labelId = `enhanced-table-checkbox-${index}`;
										return (
											<TableRow
												hover
												onClick={(event) =>
													pagination.handleRowClick(event, row._id)
												}
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row._id}
												selected={isItemSelected}
											>
												<TableCell padding="checkbox">
													<Checkbox
														color="primary"
														checked={isItemSelected}
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
												<TableCell align="right">
													<Link to={`/bophankd/donhang/chitiet/${row._id}`}>
														{row?.ma}
													</Link>
												</TableCell>
												<TableCell align="right">{row?.tongsanpham}</TableCell>
												<TableCell align="right">{row?.tongcongcu}</TableCell>
												<TableCell align="right">{row?.tongvattu}</TableCell>
												<TableCell align="right">
													{row?.tongnguyenlieu} kg
												</TableCell>
												<TableCell
													align="right"
													style={{
														fontWeight: 500,
													}}
												>
													{formatMoney(row?.tongdongia)}
												</TableCell>
												<TableCell align="right">
													{formatddMMyyyy(row?.ngaydathang)}
												</TableCell>
											</TableRow>
										);
									})}
								{pagination.emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * pagination.emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						{...tablePaginationOptions}
						count={dsDonhang.length}
						rowsPerPage={pagination.rowsPerPage}
						page={pagination.currentPage}
						onPageChange={pagination.handleChangePage}
						onRowsPerPageChange={pagination.handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>
		</>
	);
};

// const Badge = styled.div`
// 	display: inline-block;
// 	text-align: center;
// 	color: #fff;
// 	padding: 6px 10px;
// 	font-size: 15px;
// 	border-radius: 3px;
// 	&.success {
// 		background-color: #28a745;
// 	}
// `;

export default TableDonhang;
