import { useState, useEffect } from 'react';
import BackdropMaterial from '../../components/BackdropMaterial';
import Header from '../../components/Header';
import TableLoaiSanpham from './tables/TableLoaiSanpham';
import apiLoaiSanpham from '../../axios/apiLoaiSanpham';
import {
	AddButton,
	Container,
	Content,
	Filter,
	FilterSection,
	SearchBox,
	TableSection,
	Title,
	TitleWrapper,
} from './styledComponents';
import { headerDropdownLinks } from './arrayOfLinks';
import { useSearch } from '../../hooks/useSearch';

const LoaiSanpham = (props) => {
	const { queryText, setQueryText, search } = useSearch([
		'ma',
		'ten',
		'mota',
	]);

	const [loading, setLoading] = useState(false);
	const [dsSanpham, setDsSanpham] = useState([]);

	const fetchDsSanpham = async () => {
		setLoading(true);
		const { loaiSanpham } = await apiLoaiSanpham.dsLoaiSanpham();
		setDsSanpham(loaiSanpham);
		setLoading(false);
	};

	useEffect(() => {
		fetchDsSanpham();
	}, []);

	if (loading) {
		return <BackdropMaterial />;
	}

	return (
		<>
			<Container>
				<Header
					title="Loại sản phẩm"
					arrOfLinks={headerDropdownLinks}
					vaitro="admin"
				/>
				<Content>
					<FilterSection>
						<TitleWrapper>
							<Title>Danh sách loại sản phẩm</Title>
							<AddButton
								className="btn btn-primary"
								onClick={() =>
									props.history.push(
										'/admin/loaisanpham/them'
									)
								}
							>
								<span>Thêm</span>
								<i className="fas fa-plus-circle"></i>
							</AddButton>
						</TitleWrapper>
						<Filter>
							<SearchBox>
								<i className="fas fa-search"></i>
								<input
									type="text"
									placeholder="Tìm loại sản phẩm theo mã, tên và mô tả"
									value={queryText}
									onChange={(e) =>
										setQueryText(e.target.value)
									}
								/>
							</SearchBox>
						</Filter>

						<TableSection>
							<TableLoaiSanpham
								dsSanpham={search(dsSanpham)}
								onTableUpdated={() => {
									fetchDsSanpham();
								}}
							/>
						</TableSection>
					</FilterSection>
				</Content>
			</Container>
		</>
	);
};

export default LoaiSanpham;
