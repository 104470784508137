import React from "react";

const NoPermission = () => {
  return (
    <div>
      <h1>No permission</h1>
    </div>
  );
};

export default NoPermission;
